<template>
  <div
    class="product-item__ranking-item"
  >
    <p class="ranking-item__primary-wrapper">
      <img 
        src="https://img.ltwebstatic.com/images3_ccc/2024/09/11/44/1726040176dbfd0d416b9fca80fe1a267dd1f42fa1.png"
        class="ranking-item__img"
      />
      <span class="ranking-item__text">
        {{ (language.SHEIN_KEY_PC_32674 || `No.`) + rankingList.score }} {{ rankingList.rankTypeText }} 
      </span>
    </p>
    <span class="ranking-item__second">
      {{ rankingList.composeIdText }}
    </span>
  </div>
</template>
  
<script>
export default {
  name: 'ProductItemRankingList',
  props: {
    rankingList: {
      type: Object, 
      default: () => ({})
    },
    language: {
      type: Object, 
      default: () => ({})
    },
  },
  computed(){
  }
}
</script>
<style lang="less">
.product-item {
  &__ranking-item {
    background: #FFF7E2;
    padding: 7px 8px;
    color: #D58700;
    width: 100%;
    font-size: 12px;
    max-width: 100%;
    display: flex;
    align-items: center;
    color: #B4810F;
    margin: 8px 0;
    border-radius: 2px;
    line-height: 1;
    .ranking-item__primary-wrapper{
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: #D58700;
      font-weight: 700;
      font-size: 12px;
      background: none;
      border-radius: 2px; 
      height: 16px;
    }
    .ranking-item__second{
      color: #D58700;
      font-size: 12px;
      flex: 1;
      .text-overflow();
      max-width: 100%;
      margin-left: 8px;
    }
    .ranking-item__img {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
    .ranking-item__text {
      max-width: 100%;
      .text-overflow();
    }
  }
}
</style>
