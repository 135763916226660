<template>
  <div>
    <div
      v-if="isShowPromo && (activityMergeCouponDataList.length || (extraVoucherPromotionInfo && extraVoucherPromotionInfo.isExtraVoucherTextType))"
      v-expose="getEntryAnalysis('1-6-1-165')"
      v-tap="getEntryAnalysis('1-6-1-166')"
      class="product-intro__head-coupon-new"
      da-event-click="1-8-6-90"
      @click="openCouponDrawer"
    >
      <div
        class="product-intro__text"
      >
        <ProductIntroHeadPromotionNewText :activity-merge-coupon-data-list="activityMergeCouponDataList" />
      </div>
      <div class="product-intro__icon-wrap">
        <Icon
          class="product-intro__icon"
          :class="{ reverse: GB_cssRight }"
          name="sui_icon_more_right_12px_1"
          size="12px"
        />
      </div>
    </div>

    <ClientOnly>
      <template v-if="isShowDrawerAsync">
        <s-drawer
          v-model:visible="isShowDrawer"
          size="440px"
          :append-to-body="true"
          class="product-intro__promotion-drawer"
        >
          <template #title>
            {{ couponLanguage.SHEIN_KEY_PC_16011 }}
          </template>

          <div 
            v-if="isShowDrawer"
            class="promotion-container estimated-experiment"
          >
            <div
              v-if="estimatedPrice"
              class="promotion-container__cal"
            >
              <!-- 头部标题 -->
              <div
                v-if="isSatisfiedBuyMultiple"
                class="promotion-container__satisfiedBuyMultipleTitle"
              >
                <span
                  class="promotion-container__satisfiedBuyMultipleTitle-price"
                  v-html="buyMultiplePrice"
                >
                </span>
                <p class="promotion-container__satisfiedBuyMultipleTitle-text">
                  {{ template(buyMultiplePic, language.SHEIN_KEY_PC_31384 || 'With {0}Pcs purchase') }}
                </p>
              </div>
              <div
                v-else-if="isSatisfiedThreshold || isAbCoexist"
                class="promotion-container__cal-lowest"
              >
                <div class="lowest-price-wrap">
                  <p>
                    {{ finalBenefitTitle }}
                    <span 
                      v-if="isShowCountdown" 
                      class="lowest-price-num"
                    >{{ estimatedPrice }}</span>
                  </p>
                  <span 
                    v-if="!isShowCountdown" 
                    class="lowest-price-num"
                  >{{ estimatedPrice }}</span>
                  <div 
                    v-if="isShowCountdown"
                    class="promotion-container__countdown-area"
                  >
                    {{ couponLanguage.SHEIN_KEY_PC_26828 }} 
                    <div
                      class="countdown-time-tips"
                      v-html="countdownTips"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="!isSatisfiedThreshold && !(isAb || isAbCoexist || isSatisfiedBuyMultiple)"
                class="promotion-container__addMore"
                style="margin-bottom: 24px"
              >
                <div class="promotion-container__addMore-left">
                  <p
                    :class="{'promotion-container__addMore-left_textRight': GB_cssRight}"
                    v-html="addMoreText"
                  ></p>
                  <div 
                    v-if="isShowCountdown"
                    class="promotion-container__addMore-leftCount"
                  >
                    <Icon
                      class="promotion-container__addMore-leftCountIcon"
                      name="sui_icon_time_16px"
                      size="14px"
                    />
                    <p v-html="countdownTips"></p>
                  </div>
                </div>
                <div
                  v-if="isShowAddMore"
                  v-expose="getAddmoreAnalysis('1-6-1-159')"
                  v-tap="getAddmoreAnalysis('1-6-1-160')"
                  class="promotion-container__addMore-right"
                  @click="addMoreHandle"
                >
                  {{ couponLanguage.SHEIN_KEY_PC_18380 }}
                </div>
              </div>
              <!-- 计算详情，灰色背景色部分 -->
              <div 
                class="promotion-container__cal-detail" 
                :class="{'have-threshold': isSatisfiedThreshold || isAbCoexist, 'has-total-discount': showTotalDiscount }"
              >
                <div 
                  v-if="benefitTip"
                  class="benefits-tip"
                >
                  {{ benefitTip }}
                </div>
                <div v-if="showOriginOrLowest">
                  <!-- 原价 -->
                  <div
                    class="price-from"
                    :class="{ 'de-show-lowest': showLowestPriceTip }"
                  >
                    <span class="price-type">
                      {{ showLowestPrice ? couponLanguage.SHEIN_KEY_PC_30543 : (deShowOriginalDetails ? couponLanguage.SHEIN_KEY_PC_31021 : couponLanguage.SHEIN_KEY_PC_26198) }}{{ isSatisfiedBuyMultiple ? `*${buyMultiplePic}` : '' }}
                    </span>
                    <div class="price-wrap right-num">
                      <div
                        v-if="isSatisfiedBuyMultiple"
                      >
                        <p
                          class="num-bold"
                          v-html="buyMultipleRetailTotal"
                        >
                        </p>
                        <p class="num-lighter">
                          {{ buyMultipleRetailSingle }}
                        </p>
                      </div>
                      <p
                        v-else
                        class="num-bold"
                      >
                        {{ showLowestPrice ? suggestedSalePrice.value : retailPrice }}
                      </p>
                    </div>
                  </div>
                  <div 
                    v-if="showLowestPriceTip"
                    class="de-lowest-price"
                  >
                    {{ language.SHEIN_KEY_PC_31022 }}
                    <del>{{ deSuggestedSalePrice.value }}</del>
                  </div>

                  <!-- 活动总折扣 -->
                  <div
                    v-if="showTotalDiscount"
                    class="price-from hasActivity"
                    :class="{ 'for-expand': hasCompliancePrice }"
                  >
                    <span class="price-type">{{ couponLanguage.SHEIN_KEY_PC_30545 }}</span>
                    <div class="price-wrap right-num total-discount">
                      <p class="num-bold">
                        -{{ activityTotalDiscount }}
                      </p>
                      <Icon
                        :class="{ 'transfrom-arrow': isExpandTotalDiscount} "
                        name="sui_icon_more_down_16px"
                        size="12px"
                        @click="clickTotalDiscount"
                      />
                    </div>
                  </div>

                  <div 
                    v-if="isExpandTotalDiscount || !hasCompliancePrice"
                    :class="{ 'by-total-expand': hasCompliancePrice }"
                  >
                    <!-- pmsc的特价 -->
                    <div
                      v-if="originalDiscountPrice"
                      class="price-from hasActivity"
                    >
                      <span class="price-type">{{ couponLanguage.SHEIN_KEY_PC_30356 }}</span>
                      <div class="price-wrap right-num">
                        <p class="num-bold">
                          -{{ originalDiscountPrice }}
                        </p>
                      </div>
                    </div>

                    <!-- 活动N件到手价折扣 -->
                    <div
                      v-if="isSatisfiedBuyMultipleFromPromotion && isSatisfiedBuyMultipleFromPromotionInfo"
                      class="price-from hasActivity"
                    >
                      <span class="price-type">{{ isSatisfiedBuyMultipleFromPromotionInfo.text }}</span>
                      <div class="price-wrap right-num">
                        <p class="num-bold">
                          -{{ isSatisfiedBuyMultipleFromPromotionInfo.price }}
                        </p>
                      </div>
                    </div>

                    <!-- 活动 -->
                    <div 
                      v-if="showActivityDiscount"
                      class="price-from" 
                      :class="{ hasActivity }"
                    >
                      <span class="price-type">{{ priceFromType }}</span>
                      <div class="price-wrap right-num">
                        <p class="num-bold">
                          -{{ baseFromPrice }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              
                <!-- currentPrice （售价，非标签价）-->
                <div
                  v-if="hasComplianceMode"
                  class="price-from current-price"
                >
                  <span class="price-type">{{ couponLanguage.SHEIN_KEY_PC_30544 }}</span>
                  <div class="price-wrap right-num">
                    <p class="num-bold">
                      {{ complianceCurrentPrice?.amountWithSymbol }}
                    </p>
                  </div>
                </div>

                <!-- 会员 -->
                <div
                  v-if="vipDiscountPrice"
                  class="price-from hasActivity"
                >
                  <span class="price-type">SHEIN CLUB</span>
                  <div class="price-wrap right-num">
                    <p class="num-bold">
                      -{{ vipDiscountPrice }}
                    </p>
                  </div>
                </div>

                <!-- S3 会员 -->
                <div
                  v-if="s3DiscountPrice"
                  class="price-from hasActivity"
                >
                  <span class="price-type"> {{ language.SHEIN_KEY_PC_17426 || 'S3 Exclusive' }} </span>
                  <div class="price-wrap right-num">
                    <p class="num-bold">
                      -{{ s3DiscountPrice }}
                    </p>
                  </div>
                </div>

                <!-- 券 -->
                <div class="saved-money">
                  <div 
                    v-for="item in optimalCouponList"
                    v-show="showOptimalCouponItem(item)"
                    :key="item.coupon_code"
                    class="savedMoney-item-wrap"
                  >
                    <div>
                      <div>
                        <span
                          v-if="item.apply_for == 101"
                          class="left-tips"
                        >{{ template(item.discountValue.amountWithSymbol, couponLanguage.SHEIN_KEY_PC_27312) }}</span>
                        <span
                          v-else-if="item.rule?.min?.amount > 0"
                          class="left-tips"
                        >{{
                          parsePercentText(template(((1 - item?.rule?.value) * 100).toFixed(0) + '%', item.rule?.min?.amountWithSymbol, couponLanguage.SHEIN_KEY_PC_26187))
                        }}</span>
                        <span
                          v-else-if="isAb"
                          class="left-tips"
                        >{{ template(getEstimatedInfo.discountValue.amountWithSymbol, couponLanguage.SHEIN_KEY_PC_27312) }}</span>
                        <span
                          v-else
                          class="left-tips"
                        >{{ parsePercentText(template(((1 - item?.rule?.value) * 100).toFixed(0) + '%', couponLanguage.SHEIN_KEY_PC_26632)) }}</span>
                      </div>
                      <div
                        v-if="item?.is_bind === 0"
                        class="copy-code-wrap"
                      >
                        <p class="copy-code-tip">
                          {{ template(item?.coupon_code, couponLanguage.SHEIN_KEY_PC_26340) }}
                        </p>
                        <p
                          v-expose="getCopyAnalysis('1-6-1-161', item?.coupon_code)"
                          v-tap="getCopyAnalysis('1-6-1-162', item?.coupon_code)"
                          class="copy-code-btn" 
                          :data-clipboard-text="item?.coupon_code"
                          @click="CopyCode(item?.coupon_code)"
                        >
                          {{ couponLanguage.SHEIN_KEY_PC_26201 }}
                        </p>
                      </div>
                    </div>
                    <span class="right-num num-bold">-{{ item?.discountValue?.amountWithSymbol || item?.discountValue?.amount_text }}</span>
                  </div>
                </div>
                <div class="final-price">
                  <p class="finalPrice-discount-wrap">
                    <span v-if="isSatisfiedBuyMultiple">
                      {{ template(buyMultiplePic, language.SHEIN_KEY_PC_31388 || 'Buy {0} pcs to enjoy the following benefits') }}
                    </span>
                    <template v-else>
                      <span>{{ finalBenefitTitle }}</span>
                      <span 
                        v-if="estimatedDiscountPercent"
                        class="final-price-discount"
                      >-{{ estimatedDiscountPercent }}</span>
                    </template>
                  </p>
                  <div v-if="isSatisfiedBuyMultiple">
                    <p
                      class="right-num num-bold"
                      style="white-space: nowrap;"
                    >
                      {{ buyMultipleEstimatedPriceTotal }}
                    </p>
                    <p
                      class="num-lighter"
                      style="white-space: nowrap;"
                    >
                      {{ template(estimatedPrice, language.SHEIN_KEY_PC_31383 || '{0}/Pc') + '*' + buyMultiplePic }}
                    </p>
                  </div>
                  <span
                    v-else
                    class="right-num num-bold"
                  >{{ estimatedPrice }}</span>
                </div>
              </div>
              <!-- 自动领券的点击回调要加进去 -->
              <div 
                v-expose="getCartExpose()"
                style="width: 100%"
              >
                <ProductIntroBtnEntry 
                  ref="AddCartBtn"
                  :customer-config="{ 
                    isForEstimated: true,
                    openQuick: true,
                    oneClickPayBtn: false,
                    showFreeShippingButton: false,
                    showAddWish: false,
                    showTopTips: false,
                    showBottomTips: false,
                    needFix: false,
                    showBuyNow: false,
                    forbidAtmosphereFlow,
                    showBenefitText: false,
                    strengthenColor: false,
                    isAllowAutoClaim: isAllowAutoClaim,
                    isSatisfiedBuyMultipleText: isSatisfiedBuyMultiple ? template(buyMultiplePic, language.SHEIN_KEY_PC_31389 || 'ADD {0}Pcs TO CART') : ''
                  }"
                  :customer-analysis="{
                    reportData: reportData,
                  }"
                  @re-login-and-click="reLoginAndClick"
                  @add-bag-success="addBagSuccess"
                  @switch-color="handleChangeColor"
                />
              </div>

              <div class="promotion-container__cal-disclaimers">
                {{ hasComplianceMode ? couponLanguage.SHEIN_KEY_PC_30580 : couponLanguage.SHEIN_KEY_PC_26202 }}
              </div>

              <div
                v-if="!isSatisfiedThreshold && !isEstimatedstrategyNone && (isAb || isAbCoexist)"
                class="promotion-container__addMore"
                style="margin-top: 24px"
              >
                <div class="promotion-container__addMore-left">
                  <p
                    :class="{'promotion-container__addMore-left_textRight': GB_cssRight}"
                    v-html="addMoreText"
                  ></p>
                  <div 
                    v-if="isShowCountdown"
                    class="promotion-container__addMore-leftCount"
                  >
                    <Icon
                      class="promotion-container__addMore-leftCountIcon"
                      name="sui_icon_time_16px"
                      size="14px"
                    />
                    <p v-html="countdownTips"></p>
                  </div>
                </div>
                <div
                  v-if="isShowAddMore"
                  v-expose="getAddmoreAnalysis('1-6-1-159')"
                  v-tap="getAddmoreAnalysis('1-6-1-160')"
                  class="promotion-container__addMore-right"
                  @click="addMoreHandle"
                >
                  {{ couponLanguage.SHEIN_KEY_PC_18380 }}
                </div>
              </div>
            </div>

            <template
              v-if="showPromotion"
            >
              <div class="promotion-container__drawer-h">
                {{ couponLanguage.SHEIN_KEY_PC_21927 }}
              </div>
              <div class="promotion-container__drawer">
                <div
                  v-if="promotionInfoDesc.length"
                  class="promotion-container__drawer-activity"
                >
                  <div class="promotion-container__drawer-rt">
                    <div
                      v-for="item in promotionInfoDesc"
                      :key="item.typeId"
                      class="promotion-container__drawer-promotion"
                    >
                      <a
                        v-tap="{
                          id: '1-8-6-221',
                          data: {
                            promotionactivity: item.id,
                          },
                        }"
                        v-expose="{
                          id: '1-8-6-220',
                          data: {
                            promotionactivity: item.id,
                          },
                        }"
                        class="promotion-container__drawer-item"
                        :style="{'cursor': !isBrandFlash ? 'pointer' : ''}"
                      
                        @click="jumpToAdd(item)"
                      >
                        <div
                          v-if="(item.rangeList || item.typeId == 24) && !isPerSave(item)"
                          class="promotion-container__drawer-single"
                        >
                          <div
                            v-if="item.title || item.lable"
                            class="promotion-container__drawer-promo"
                          >
                            <span>{{ item.title || item.lable }}</span>
                            <Icon
                              v-if="[10, 30].includes(Number(item.typeId || 0)) || item.scId"
                              name="sui_icon_more_right_18px"
                              size="14px"
                              :is-rotate="GB_cssRight"
                            />
                          </div>
                          <div class="promotion-container__drawer-content">
                            <div
                              v-for="r in item.tips.list"
                              :key="r"
                              class="promotion-container__drawer-tips"
                            >
                              {{ r }}
                            </div>
                            <div 
                              v-if="item.typeId == 28" 
                              class="promotion-container__drawer-difftip"
                            >
                              {{ couponLanguage.SHEIN_KEY_PC_21580 }}
                            </div>
                          </div>
                        </div>
                        <div 
                          v-else 
                          class="promotion-container__drawer-multi"
                        >
                          <span>{{ promotionInfoTextSel(item) }}</span>
                          <Icon
                            v-if="!isBrandFlash && ([10, 30].includes(Number(item.typeId || 0)) || item.scId )"
                            style="font-weight:bold"
                            :name="IS_RW ? 'sui_icon_more_right_14px': 'sui_icon_more_right_14px_1'"
                            size="14px"
                            :is-rotate="GB_cssRight"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="drawerPaymentInfos.length"
                  class="promotion-container__drawer-payment" 
                >
                  <div class="promotion-container__payment-tip">
                    {{ language.SHEIN_KEY_PC_30361 }}
                  </div>
                  <template 
                    v-for="(item, index) in drawerPaymentInfos" 
                    :key="item.online_pay_id + item.paymentCode"
                  >
                    <div
                      v-show="isExpandPayment || (!isExpandPayment && index < 3)"
                      class="promotion-container__payment-item"
                      v-html="item.text"
                    >
                    </div>
                  </template>
                  <div 
                    v-if="drawerPaymentInfos.length > 3" 
                    class="promotion-container__payment-arrow" 
                    @click="expandMorePayment"
                  >
                    <Icon
                      :class="{ 'transfrom-arrow': isExpandPayment } "
                      name="sui_icon_more_down_16px"
                      size="16px"
                    />
                  </div>
                </div>
              </div>
            </template>

            <ExtraVoucherPromotion
              v-if="extraVoucherPromotionInfo"
              :extra-voucher-promotion-info="extraVoucherPromotionInfo"
            />

            <div 
              v-if="currCouponList && currCouponList.length > 0"
              class="product-intro__couponDrawerNew"
            >
              <div class="promotion-container__drawer-h">
                {{ couponLanguage.SHEIN_KEY_PC_20067 }}
              </div>
              <ul
                class="coupon-list-new"
                da-expose-code="productIntroCouponList"
              >
                <li
                  v-for="(item, index) in currCouponList"
                  :key="`get-coupon-${index}`"
                  v-expose="getCouponAnalysis(item)"
                  class="coupon-item-new"
                >
                  <coupon-item
                    :item="item"
                    :language="couponLanguage"
                    :expanded="expandedCoupon"
                    :show-coupon-code="item.is_bind === 0"
                    :is-login="isLogin"
                    :abt-estimated="true"
                    :show-coupondown="showCoupondown(item)"
                    :abt-new-user-gifts="abtNewUserGifts"
                    :must-show-min="false"
                    :update-style="updateStyle"
                    :theme-config="couponItemThemeConfig(item)"
                    :is-for-detail="true"
                    show-shipping-coupon-tips
                    @trigger-expand="(id) => (expandedCoupon = id)"
                    @trigger-collapse="expandedCoupon = ''"
                    @login-handle="loginHandle"
                    @get-coupon-handle="getCouponHandle"
                  />
                </li>
              </ul>
            </div>
          </div>
        </s-drawer>
      </template>

      <AddOnDialog
        v-model:visible="addMoreDialogVisible"
        :query-params="preParams"
        :sa-info="saInfo"
        :config="{
          isClickToDetail: true
        }"
        :coupon="optimalCouponCode"
        type="coupon"
      />

      <s-dialog
        v-model:visible="isProductsModalShow"
        :type="'W996'"
        :show-close="true"
        :close-on-click-modal="true"
        :immediately-render="true"
        :append-to-body="true"
        class="products-modal"
      >
        <template #title>
          <div>{{ couponLanguage.SHEIN_KEY_PC_16480 }}</div>
        </template>
        <block-status 
          v-if="isProFetching" 
          :language="couponLanguage"  
          type="loading" 
        />
        <block-status
          v-else-if="isProFetchError"
          :language="couponLanguage"
          type="error"
          @refresh="getPromProducts"
        />
        <block-status
          v-else-if="promotion.items.length === 0"
          :language="couponLanguage"
          type="empty"
        />
        <div v-else>
          <p class="sub-title">
            {{ promotion.items.length }} {{ couponLanguage.SHEIN_KEY_PC_14931 }}
          </p>
          <div
            v-if="promotion.coupon_type_id === '2' && advShow"
            class="advert-notice"
          >
            <Icon
              name="sui_icon_notice_18px"
              size="16px"
              :is-rotate="GB_cssRight"
            />
            <div class="value">
              <p v-html="noticeText"></p>
            </div>
            <Icon
              class="icon-close-new"
              name="sui_icon_closed_14px_1" 
              size="14px"
              color="#999"
              @click="advShow = false"
            />
          </div>
          <ul class="product-list">
            <production
              v-for="(item, i) in promotion.items"
              :key="item.id"
              :item-data="item"
              :language="couponLanguage"
              :show-quick-ship-tag="false"
              @click="popQuickView(item, i)"
            />
          </ul>
        </div>
      </s-dialog>
    </ClientOnly>
  </div> 
</template>

<script>
import 'public/src/icon/arrow-down.svg'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { htmlDecode, template, CountDown, debounce, cloneDeep } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import Clipboard from 'clipboard'
import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
import { promotionCenter } from 'public/src/pages/common/promotion'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import { defineAsyncComponent } from 'vue'
import BlockStatus from 'public/src/pages/user_coupon/components/blockStatus.vue'
import Production from 'public/src/pages/user_coupon/components/production.vue'
import { couponThemeConfig } from 'public/src/pages/components/coupon/mall/utils.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/salePriceWordSize'
import getQuickView from '../utils/getQuickViewPopover'
import ProductIntroBtnEntry from 'public/src/pages/goods_detail_v2/components/ProductIntroBtnBox/ProductIntroBtnEntry.vue'
import ProductIntroHeadPromotionNewText from 'public/src/pages/goods_detail_v2/components/ProductIntroHeadPromotionNewText.vue'
import { parsePercentText } from 'public/src/pages/goods_detail_v2/utils/ar.js'

const { GB_cssRight } = gbCommonInfo
const { CLOSE_BRAND_FLASH_PROMOTION_FLOOR } = gbCommonInfo || {}
daEventCenter.addSubscriber({ modulecode: '1-8-6' })



export default {
  name: 'ProductIntroHeadPromotionNew',
  components: { 
    ClientOnly,
    ProductIntroBtnEntry,
    ProductIntroHeadPromotionNewText,
    couponItem: defineAsyncComponent(() => import('public/src/pages/components/coupon/mall/index.vue')),
    AddOnDialog: defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog.vue')),
    ExtraVoucherPromotion: defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/ExtraVoucher/ExtraVoucherPromotion.vue')),
    BlockStatus,
    Production, //: defineAsyncComponent(() => import('public/src/pages/user_coupon/components/production.vue'))
    Icon
  },
  data() {
    return {
      expandedCoupon: '',
      // couponLanguage: {},
      isShowDrawer: false,
      isLogin: isLogin(),
      exposeItemAnalysisInstance: null,
      E_Price_AddOnItem: 'AddOnItem',
      isGetAgain: false,
      isAddmoreAgain: false,
      addMoreDialogVisible: false,
      isProductsModalShow: false,
      isProFetching: false,
      isProFetchError: false,
      selectedCoupon: null,
      promotion: { items: [] },
      advShow: true,
      GB_cssRight,
      supportIds: [3, 8, 10, 24, 29, 30, 31],
      updateStyle: false,
      updateTimes: 0,
      countTimer: null,
      IS_USE_NEW_ADD_MORE: false,
      isExpandPayment: false,
      isExpandTotalDiscount: false,
      parsePercentText,
      isShowDrawerAsync: false
    }
  },
  computed: {
    ...mapState([
      'realtimePricePromotionStatus',
      'promotionLoaded',
      'productIntroData',
      'isLoginChange',
      'promotionData',
      'viewPromotion',
      'IS_RW',
      'brandDetail',
      'isUseNewAddMore',
      'language',
      'skuInfo',
      'promotionInfoFromServer',
      'DETAIL_STREAM_PART'
    ]),
    ...mapGetters([
      'screenAbtConfig',
      'getEstimatedInfo',
      's3ClubPriceInfo',
      'currCouponList',
      'promotionInfo',
      'skcPromotionInfo',
      'mallCode',
      'newUserCouponInfo',
      'estimatedAbtest',
      'isShowAddMore',
      'optimalCoupon',
      'discountPercent',
      'isShowCountdown',
      'bestActivity',
      'bestEndTime',
      'isEndTimeIn24',
      'isAllowAutoClaim',
      'forbidAtmosphereFlow',
      'currentMallDetail',
      'optimalCouponList',
      'skuPromotionInfo',
      'paymentInfoContent',
      'currSalePrice',
      'complianceMode',
      'complianceModeDe',
      'suggestedSalePrice',
      'complianceCurrentPrice',
      'newFlashPromotion',
      'activityTotalDiscount',
      'isInversion',
      'extraVoucherPromotionInfo',
      'isInversionDe',
      'deDiscountInfo',
      'flashGoods',
      'flashType',
      'couponLanguage'
    ]),
    ...mapGetters('Size', ['sizeComposeData']),
    price() {
      return this.skuInfo?.mall?.[this.mallCode] || this.currentMallDetail
    },
    hasCompliancePrice(){
      return this.showLowestPrice || this.deShowOriginalDetails
    },
    hasComplianceMode(){
      return this.complianceMode || this.complianceModeDe
    },
    deShowOriginalDetails(){ 
      const retailPrice = this.getEstimatedInfo?.retailPrice?.amount || 0
      const salePrice = this.complianceCurrentPrice?.amount || 0
      return this.complianceModeDe && Number(retailPrice) > Number(salePrice)
    },
    showOriginOrLowest(){
      return !this.hasComplianceMode || this.hasCompliancePrice
    },
    showLowestPrice(){
      return this.suggestedSalePrice?.show
    },
    s3DiscountPrice(){
      const { s3ClubPriceInfo } = this
      if(!s3ClubPriceInfo) return ''
      if(this.isSatisfiedBuyMultiple){
        return s3ClubPriceInfo?.double_price_info?.double_display_price?.double_prev_discount_value?.amount_text
      }
      return s3ClubPriceInfo?.prev_discount_value?.amount_text
    },
    deSuggestedSalePrice(){
      return this.deDiscountInfo?.suggestedSalePrice || {}
    },
    showLowestPriceTip(){
      return this.deSuggestedSalePrice?.show && this.deShowOriginalDetails
    },
    showPromotion(){
      return (this.promotionInfoDesc?.length || this.drawerPaymentInfos?.length) && !(this.getEstimatedInfo?.isAb || this.getEstimatedInfo?.isAbCoexist)
    },
    abtNewCoupon(){
      return this.screenAbtConfig?.NewCoupon?.p?.NewCoupon
    },
    abtNewUserGifts(){
      return this.isShowEstimatedStyle && ['New1', 'New2'].includes(this.abtNewCoupon)
    },
    filterCouponList(){
      return this.currCouponList.filter(item => item.coupon !== this.newUserFilterCoupon?.coupon_code)  //被强化的券要从促销入口中剔除
    },
    onlyOnePromotion(){
      const { isExtraVoucherTextType  } = this.extraVoucherPromotionInfo || {}
      return !this.hasNew2Coupon && !isExtraVoucherTextType && !this.filterCouponList.length && !this.drawerPaymentInfos.length && this.promotionInfoDesc.length == 1 && !this.isAb
    },
    onlyActivityDataList(){
      const finalData = []
      this.promotionInfoDesc.forEach((item) => {
        const text = this.promotionInfoTextSel(item)?.trim?.()
        if (!text) return
        finalData.push({ text })
      })
      return finalData
    },
    onlyCouponDataList(){
      if (this.isAb && !this.isAbCoexist && !this.isEstimatedstrategyNone) {
        return [{ text: template(this.getEstimatedInfo?.discountValue?.amountWithSymbol, this.couponLanguage.SHEIN_KEY_PC_27312) }]
      }
      const finalData = []
      this.filterCouponList.forEach((item) => {
        const tempTextList = []
        const _couponRuleFirst = item.coupon_rule?.[0] || {}

        // 是无门槛券
        const _isWithoutThreshold = _couponRuleFirst?.min?.amount && Number(_couponRuleFirst?.min?.amount) === 0 && !_couponRuleFirst?.max?.amountWithSymbol
        const _LANG_KEY = _isWithoutThreshold && this.couponLanguage.SHEIN_KEY_PC_27864 || _couponRuleFirst?.coupon_gift_id && this.couponLanguage.SHEIN_KEY_PC_18189 || this.couponLanguage.SHEIN_KEY_PC_15957

        const _pushData = (str, useTrim = true) => {
          const _str = useTrim ? str?.trim?.() : str
          if (!_str) return
          tempTextList.push(_str)
        }
        if (item?.apply_for == 101) {
          let discountValue = this.getEstimatedInfo?.coupons?.find?.(estimatedCoupons => estimatedCoupons.apply_for == 101)?.discountValue?.amountWithSymbol
          _pushData(template(discountValue, this.couponLanguage.SHEIN_KEY_PC_27312))
        } else if (['5', '6'].includes(item?.apply_for) || (item?.apply_for == '9' && item?.shipping_discount_type == 0)) {
          let text = this.couponLanguage.SHEIN_KEY_PC_15911
          if(_isWithoutThreshold){
            text += (' ' + this.couponLanguage.SHEIN_KEY_PC_27865)
          }
          _pushData(text)
        } else if (item?.apply_for == '9' && item?.shipping_discount_type == 1){
          const money = item?.coupon_type_id == 1 ? item.coupon_rule?.[0]?.value?.amountWithSymbol : this.percentFormat(item.coupon_rule?.[0]?.value?.amount)
          _pushData(money + ' ' + this.couponLanguage.SHEIN_KEY_PC_29184)
        } else if (item?.coupon_type_id == 1) {
          _pushData(template(
            item.coupon_rule?.[0]?.value?.amountWithSymbol,
            htmlDecode({
              text: _LANG_KEY,
            })
          ))
        } else {
          _pushData(template(
            this.percentFormat(item.coupon_rule?.[0]?.value?.amount),
            htmlDecode({
              text: _LANG_KEY,
            })
          ))
        }

        _pushData(this.couponSubTitle(item), false)
        tempTextList.length && finalData.push({ text: tempTextList.join('') })
      })
      return finalData
    },
    activityMergeCouponDataList() {
      if (this.isAb && !this.isEstimatedstrategyNone) {
        return [{ text: template(this.getEstimatedInfo?.discountValue?.amountWithSymbol, this.couponLanguage.SHEIN_KEY_PC_27312) }]
      }
      const { bestPay, otherPay } = this.onlyPaymendInfos || {}
      const finalList = [bestPay, ...this.onlyCouponDataList, ...this.onlyActivityDataList, ...otherPay]
      
      const { isExtraVoucherTextType, extraVoucherTextTypeTexts, discountAmountWithSymbol } = this.extraVoucherPromotionInfo || {}
      if(isExtraVoucherTextType){
        finalList.unshift({
          text: template(discountAmountWithSymbol, extraVoucherTextTypeTexts.extraVoucher), // 不要注释，不然会被过滤掉
          exposeConfig: {
            id: '1-8-6-225', data: { location: 1 }
          },
          tapConfig: {
            id: '1-8-6-226', data: { location: 1 }
          },
          isSpecial: true
        })
      }

      if(this.hasNew2Coupon) {
        const { newUserDiscount } = this.newUserCouponInfo || {}  
        finalList.unshift({
          text: parsePercentText(template(newUserDiscount, this.couponLanguage.SHEIN_KEY_PC_30945)),
          exposeConfig: {
            id: '1-8-6-171'
          },
          tapConfig: {
            id: '1-8-6-172',
            data: { operation_results: 1 }
          },
          isSpecial: true
        })
      }
      return finalList.filter(item => !!item?.text?.trim()) 
    },
    hasNew2Coupon(){
      return (this.newUserFilterCoupon && this.abtNewCoupon === 'New2') //|| true
    },
    // 支付方式列表
    onlyPaymendInfos() {
      const showAbt = this.screenAbtConfig?.paydiscountshowdetail?.p?.paydiscountshowdetail
      if(showAbt !== 'Show') return  { bestPay: null, otherPay: [] }
      let { iconsList = [], sortedPayments = [], paymentInfos = [] } = this.paymentInfoContent || {}
      // 1.根据sortedPayments 优先级排序，查不到优先级结果的 过滤不展示
      sortedPayments.sort((a, b)=>a.sort - b.sort)
      let sortedFinalList = sortedPayments.map(item => {
        let currPayment = paymentInfos.find(payment => {
          return payment?.all_payment?.includes(item.paymentCode)
        })
        if(!currPayment) return null
        // 根据iconsList 替换icon, 和支付方式列表用‘paymentCode’去做遍历匹配icon
        let cccInfo = iconsList.find(info => {
          return info.paymentCode === item.paymentCode
        })
        return {
          ...item,
          icon: cccInfo?.icon || '',
          rule: currPayment.rules?.[0],
        }
      }).filter(item => Boolean(item) && (item.title || item.icon))
      
      let bestPay = null
      let otherPay = []
      if(!sortedFinalList?.length) return { bestPay, otherPay }
      // 2.判断是否存在当前到主价格可达门槛的支付方式, 将首先满足门槛作为优先级最高的排在促销楼层首位，定义为最佳支付优惠
      // 取商品的商详主价格，含义有到手价取到手，无到手价取实际活动价/售价
      let mainPrice = Number(this.getEstimatedInfo?.value?.amount || this.currSalePrice)
      let bestIndex = sortedFinalList.findIndex(it => {
        if(it.rule?.type == 2) return false
        const threshold = Number(it.rule?.min?.amount || 0)
        return mainPrice > threshold
      })
      if(bestIndex > -1){
        bestPay = sortedFinalList?.[bestIndex] || null
        bestPay.isBestPay = true
        bestPay.text = this.paymentTextHandle(bestPay, true)
        // 其他非最佳支付方式，放在促销楼层后面
        const otherList = [...sortedFinalList.slice(0, bestIndex), ...sortedFinalList.slice(bestIndex + 1)]
        otherPay = otherList.map(item => ({
          ...item,
          text: this.paymentTextHandle(item)
        }))
      }else{
        otherPay = sortedFinalList.map(item => ({
          ...item,
          text: this.paymentTextHandle(item)
        }))
      }
      return { bestPay, otherPay }
    },
    // 促销弹窗展示的支付方式列表 - 新增子模块“Discounts For Payment“
    drawerPaymentInfos() {
      let { bestPay, otherPay } = this.onlyPaymendInfos || {}
      const bestPayCopy = Object.assign({}, bestPay, { isBestPay: false })
      bestPayCopy.text = this.paymentTextHandle(bestPayCopy, true)
      otherPay = otherPay.map(item => { 
        return {
          ...item,
          text: this.paymentTextHandle(item, true)
        }
      })
      return [bestPayCopy, ...otherPay].filter(it => it?.text)
    },
    countdownTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
    },
    newUserFilterCoupon(){
      const { newUserCoupon } = this.newUserCouponInfo || {}
      const isFilterNewCoupon = this.abtNewUserGifts && newUserCoupon
      return isFilterNewCoupon ? newUserCoupon : null
    },
    isShowPromo() {
      // 确保活动信息处理完才展示促销入口文案
      if (!this.promotionLoaded) {
        return false
      }
      let { isExtraVoucherTextType } = this.extraVoucherPromotionInfo || {}
      if (this.currCouponList.length || this.drawerPaymentInfos.length || isExtraVoucherTextType) return true
      const isNotDealerDown = (item = {}) => item.typeId == 31 && item.promotionLogoType != 3 // 用于过滤同一活动Id--31但非商家直降活动的情况

      const promotionResult =  (
        this.promotionInfo.length &&
        this.promotionInfo.find((i) => {
          if (isNotDealerDown(i)) return false
          const normalFlashStyle = [1, 5].includes(this.flashType) || (this.flashType == 4 && this.newFlashPromotion)
          if (+i.typeId === 10 && normalFlashStyle) return true // Normal Flash
          if (+i.typeId === 10 && !this.newFlashPromotion) return false // Special Flash
          return PROMOTION_CONFIG.allPromotionTypeIds.includes(+i.typeId) && ![8, 12, NaN].includes(+i.typeId)
        })
      )
      return promotionResult
    },
    promotionInfoDesc() {
      // ab价
      if (this.isAb || this.isAbCoexist) {
        return [this.getEstimatedInfo?.promotionInfo]
      }
      // 需要过滤：未命中展示新人/特殊闪购的分支，promotion弹窗和入口都不应该展示新人/特殊闪购
      const isSpecialFlash = [2, 3].includes(this.flashType) && this.newFlashPromotion?.price?.amountWithSymbol
      const isZoneFlash = [4].includes(this.flashType) && this.newFlashPromotion?.price?.amountWithSymbol
      const isNotDealerDown = (item = {}) => item.typeId == 31 && item.promotionLogoType != 3 // 用于过滤同一活动Id--31但非商家直降活动的情况
      let filterList = this.promotionData?.filter?.(
        (item) =>
          PROMOTION_CONFIG.allPromotionTypeIds.includes(+item.typeId) && !(
            [8, 12, 29, 11].includes(+item.typeId)
            || (item.typeId == 24 && item.ruleType === 'no_discount' && Number(item.singleNum) && !Number(item.orderNum))
            || (item.typeId == 10 && [2, 3].includes(item.flashType) && !isSpecialFlash)
            || isNotDealerDown(item)
            || (item.typeId == 10 && [4].includes(item.flashType) && !isZoneFlash)
          ) && !!this.promotionInfo.find(prom => item.id == prom.id)
      )

      const bestId = this.getEstimatedInfo?.promotionInfo?.id
      filterList.forEach((item, index)=>{
        if(item.id === bestId){
          filterList.unshift(filterList.splice(index, 1)[0])
        }
      })
      let closeBrandFlashPromotionFloor = CLOSE_BRAND_FLASH_PROMOTION_FLOOR
      if (closeBrandFlashPromotionFloor == 1 && this.flashType == 6) {
        filterList = filterList.filter(item => item.typeId != 10)
      }
      // 倒挂要拿闪购PFES闪购的字段，不能屏蔽，但是展示上屏蔽
      if(this.isInversion || this.isInversionDe) filterList = filterList.filter(item => item.typeId != 10)
      return filterList
    },
    E_Price_Cal(){
      return this.screenAbtConfig?.EstimatedPrice?.p?.E_Price_Cal // "Have_Threshold", //Non_Threshold // None
    },
    isSatisfiedThreshold() {
      return !!this.getEstimatedInfo?.isSatisfied && this.getEstimatedInfo?.value
    },
    isSatisfiedBuyMultiple() {
      return !!this.getEstimatedInfo?.isSatisfiedBuyMultiple
    },
    isSatisfiedBuyMultipleFromPromotion() {
      return !!this.getEstimatedInfo?.isSatisfiedBuyMultipleFromPromotion
    },
    isSatisfiedBuyMultipleFromPromotionInfo() {
      let { buyMultiplePic, mulSaleInfo, promType9Info } = this.getEstimatedInfo || {}
      let text = parsePercentText(template(buyMultiplePic, this.percentFormat((100 - promType9Info.discountValue * 100)), this.language.SHEIN_KEY_PC_31386 || 'Buy {0} Get {1} OFF'))
      let price = mulSaleInfo.total_discount_price?.amountWithSymbol
      if (text && price) return { text, price }
      return null
    },
    buyMultipleRetailTotal() {
      let { isSatisfiedBuyMultipleFromCoupon, isSatisfiedBuyMultipleFromPromotion, mulSaleInfo } = this.getEstimatedInfo || {}
      if (isSatisfiedBuyMultipleFromCoupon) {
        return this.price?.double_price_info?.double_retail_price?.amountWithSymbol
      }
      if (isSatisfiedBuyMultipleFromPromotion) {
        return mulSaleInfo?.total_retail_price?.amountWithSymbol
      }
      return ''
    },
    buyMultipleRetailSingle() {
      return `${template(this.price.retailPrice.amountWithSymbol, this.language.SHEIN_KEY_PC_31383 || '{0}/Pic')}*${this.buyMultiplePic}`
    },
    buyMultiplePic() {
      return this.getEstimatedInfo?.buyMultiplePic
    },
    buyMultipleEstimatedPriceTotal() {
      let { isSatisfiedBuyMultipleFromCoupon, isSatisfiedBuyMultipleFromPromotion, doubleValue, mulSaleInfo } = this.getEstimatedInfo || {}
      if (isSatisfiedBuyMultipleFromCoupon) {
        return doubleValue?.amountWithSymbol
      }
      if (isSatisfiedBuyMultipleFromPromotion) {
        return mulSaleInfo?.total_sale_price?.amountWithSymbol
      }
      return this.price.double_price_info
    },
    buyMultipleEstimatedPriceSingle () {
      return this.getEstimatedInfo?.value?.amountWithSymbol
    },
    buyMultiplePrice() {
      return salePriceWordSize({
        priceStr: template(this.estimatedPrice, this.language.SHEIN_KEY_PC_31383 || '{0}/Pc'),
        gate: this.fsAbt?.sheinappwordsize?.p?.sheinappwordsize === 'newprice',
      })
    },
    isEstimatedstrategyNone() {
      return this.getEstimatedInfo?.isEstimatedstrategyNone
    },
    isShowEstimatedStyle(){
      // 命中了到手价实验，不一定返回了有效到手价
      let abtValue = this.E_Price_Cal
      return abtValue === 'Have_Threshold' || abtValue === 'Non_Threshold'
    },
    E_Time_Limit(){
      return this.screenAbtConfig?.EstimatedPrice?.p?.E_Time_Limit
    },
    isAb () {
      return this.getEstimatedInfo?.isAb
    },
    isAbCoexist () {
      return this.getEstimatedInfo?.isAbCoexist
    },
    // 活动
    promotionDiscountInfo() {
      let currPromotionDiscountInfo = this.skuInfo?.mall?.[this.mallCode]?.promotion_discount_info || this.currentMallDetail?.promotion_discount_info
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromCoupon } = this.getEstimatedInfo || {}
      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromCoupon) {
          let result = currPromotionDiscountInfo.map(item => ({ ...item, discount_price: this.price.double_price_info?.double_discount_amount }))
          return result?.filter(item => +item.type_id !== 29) || []
        }
      }
      return currPromotionDiscountInfo?.filter(item => +item.type_id !== 29) || []
    },
    // 原价-pmsc的特价
    originalDiscountPrice() {
      let currOriginalDiscountPrice = ''
      if(this.deShowOriginalDetails){
        currOriginalDiscountPrice = this.skuInfo?.mall?.[this.mallCode]?.retailOriginalDiscountPrice || this.currentMallDetail?.retailOriginalDiscountPrice
      } else{
        currOriginalDiscountPrice = this.skuInfo?.mall?.[this.mallCode]?.original_discount_price || this.currentMallDetail?.original_discount_price
      }
      const { getEstimatedInfo, price } = this
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, mulSaleInfo } = getEstimatedInfo || {}
      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          let result = mulSaleInfo?.origin_discount_price || ''
          return +result.amount ? result.amountWithSymbol : ''
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          let result = price.double_price_info?.double_original_discount_price
          return +result?.amount ? result?.amountWithSymbol : ''
        }
      }
      const { amount, amountWithSymbol } = currOriginalDiscountPrice
      return  +amount ? amountWithSymbol : '' // 有时价格返回'0.00'
    },
    showTotalDiscount(){
      return this.hasCompliancePrice && (this.originalDiscountPrice || this.showActivityDiscount)
    },
    vipDiscountPrice() {
      if(!this.getEstimatedInfo?.isPaidUser) return ''

      const { getEstimatedInfo, price } = this
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, mulSaleInfo } = getEstimatedInfo 

      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return mulSaleInfo.total_vip_discount_price?.amountWithSymbol ?? ''
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return price.double_price_info?.double_display_price?.double_discount_amount?.amountWithSymbol ?? ''
        }
      }
      
      let currPrevDiscountValue = this.skuInfo?.mall?.[this.mallCode]?.prev_discount_value || this.currentMallDetail?.prev_discount_value
      return currPrevDiscountValue?.amountWithSymbol ?? ''
    },
    showActivityDiscount(){
      return this.priceFromType && this.baseFromPrice
    },
    baseFromPrice() {
      if (this.newFlashPromotion?.discountValue) {
        if (this.getEstimatedInfo?.isSatisfiedBuyMultiple && this.getEstimatedInfo?.isSatisfiedBuyMultipleFromCoupon) {
          let { amount, amountWithSymbol } = this.price?.double_price_info?.double_display_price?.double_retail_discount_amount
          return +amount ? amountWithSymbol : ''
        }
        let { amount, amountWithSymbol } = this.newFlashPromotion?.discountValue
        return +amount ? amountWithSymbol : ''
      }
      const { amount, amountWithSymbol } = this.promotionDiscountInfo?.[0]?.discount_price || {}
      return +amount ? amountWithSymbol : '' // 有时价格返回'0.00'
    },
    hasActivity(){
      if (this.isAb || this.isAbCoexist) {
        return false
      }
      return this.supportIds.includes(Number(this.activityTypeId)) || (this.salePrice && this.retailPrice !== this.salePrice) 
    },
    priceFromType(){
      const { promotionLogoType } = this.getEstimatedInfo?.promotionInfo || {}
      const typeId = this.promotionDiscountInfo[0]?.type_id
      if(this.flashGoods){
        const flashMap = {
          '1': this.couponLanguage.SHEIN_KEY_PC_30410, // Flash Sale Price 常规闪购
          '2': this.couponLanguage.SHEIN_KEY_PC_26188, //'New User Sale' 新人闪购
          '3': this.couponLanguage.SHEIN_KEY_PC_26189, // Exclusive Sale Price 特殊闪购
          '4': this.couponLanguage.SHEIN_KEY_PC_30410, // Flash Sale Price 常规闪购
          '5': this.couponLanguage.SHEIN_KEY_PC_30410, // Flash Sale Price 常规闪购
          '6': this.couponLanguage.SHEIN_KEY_PC_29876, // Flash Sale Price 常规闪购
        }
        return flashMap[this.flashType]
      }else{
        const isDealerFallDown = typeId == 31 && promotionLogoType == 3 // 命中商家直降活动
        const activityMap = {
          '3': this.couponLanguage.SHEIN_KEY_PC_26191, // Limited-Time Price 限时折扣
          '8': this.couponLanguage.SHEIN_KEY_PC_26194, // Exclusive Sale Price APP专项折扣（只有APP端需要计算)
          '24': this.couponLanguage.SHEIN_KEY_PC_26192, // Limited Stock Price 打折限购
          // '29': this.couponLanguage.SHEIN_KEY_PC_26190, // Shein Club Price 付费会员
          '30': this.couponLanguage.SHEIN_KEY_PC_30358, // Discount Price 一口价
          '31': isDealerFallDown 
            ? this.couponLanguage.SHEIN_KEY_PC_30357 
            : this.couponLanguage.SHEIN_KEY_PC_30355, // Price Drop 基础调价中的商家直降 || Discount deal 基础调价
        }
        return activityMap[typeId] || ''
      }
    },
    noticeText() {
      if (!this.promotion) {
        return ''
      }
      return this.template(
        this.promotion.coupon,
        this.couponLanguage.SHEIN_KEY_PC_17695
      )
    },
    estimatedPrice(){ // 最终到手价
      return this.getEstimatedInfo?.value?.amountWithSymbol
    },
    estimatedDiscountPercent () { // 到手价折扣率
      return !this.hasComplianceMode && parsePercentText(this.getEstimatedInfo?.discountPercent)
    },
    thresholdValueNum(){
      return this.getEstimatedInfo?.thresholdValue?.amount
    },
    thresholdValue(){ // 门槛价格
      return this.getEstimatedInfo?.thresholdValue?.amountWithSymbol
    },
    retailPrice(){ // 原价
      return this.getEstimatedInfo?.retailPrice?.amountWithSymbol
    },
    salePrice(){
      return this.getEstimatedInfo?.salePrice?.amountWithSymbol
    },
    discountValue(){ // 券抵扣金额
      return this.getEstimatedInfo?.discountValue?.amountWithSymbol
    },
    activityTypeId(){
      return this.getEstimatedInfo?.promotionInfo?.typeId
    },
    optimalCouponCode(){
      return this.optimalCoupon?.coupon_code
    },
    isOptimalStacked() {
      return this.getEstimatedInfo?.isStacked
    },
    saInfo() {
      return {
        promotion_code: this.optimalCouponCode,
        state: 'goods_detail_coupon_add',
        activity_from: 'goods_detail_coupon_add',
        abtest: 'addnewhotJson'
      }
    },
    preParams() {
      const { goods_id, cat_id } = this.productIntroData?.detail
      const isTopGoods = this.screenAbtConfig?.Gatheringorders?.p?.Gatheringorders === 'Topping'
      const isOpenRecommend = this.screenAbtConfig?.EstimatedNothreShowType?.p?.recommendtype == '1'
      let params = {
        sceneId: 152, // 凑单列表值
        addOnType: 2,
        cateIds: [cat_id] // 类目ID

      }
      if(isTopGoods) {
        params.adp = [goods_id]
      }else{
        params.goodsIds = [goods_id] // 商品ID
      }
      if(isOpenRecommend) {
        // 基于商详商品推出凑单商品
        params.mainGoodsIds = [goods_id]
      }
      return params
    },
    reportData() {
      let { getEstimatedInfo } = this || {}
      let threshold
      if (getEstimatedInfo) {
        if (getEstimatedInfo?.isSatisfiedBuyMultiple) {
          threshold = 6
        } else if (getEstimatedInfo?.isAb) {
          threshold = 3
        } else if (getEstimatedInfo?.isAbCoexist) {
          threshold = getEstimatedInfo?.optimalCoupon?.satisfied ? 4 : 5
        } else {
          threshold = getEstimatedInfo?.optimalCoupon?.satisfied ? 1 : 2
        }
      }
      return {
        location: 'promotion_popup',
        threshold: threshold
      }
    },
    addMoreText() {
      let isEstimatedNothreShowTypeNew1 = this.getEstimatedInfo?.EstimatedNothreShowType == 'New1'
      let new1Lan = this.language.SHEIN_KEY_PC_28095 || 'this item only {0} if add-ons {1} more'
      let new2Lan = this.language.SHEIN_KEY_PC_28096 || 'add-ons {1} more to enjoy  {2} off'
      if (this.GB_cssRight) {
        new1Lan = new1Lan.replace(/\s/g, '&nbsp;')
        new2Lan = new2Lan.replace(/\s/g, '&nbsp;')
      }
      const strWithSpan = (str) => `<span>${str}</span>`
      let value = this.getEstimatedInfo?.optimalCoupon?.after_coupon_price?.amountWithSymbol
      let resultStr = ''
      resultStr = isEstimatedNothreShowTypeNew1 ? 
        template(strWithSpan(value), strWithSpan(this.getEstimatedInfo?.needPrice.amountWithSymbol), new1Lan) :
        template('', strWithSpan(this.getEstimatedInfo?.needPrice?.amountWithSymbol), strWithSpan(value), new2Lan)
      return resultStr
    },
    isBrandFlash() {
      return this.flashType == 6
    },
    benefitTip() {
      if (this.isSatisfiedBuyMultiple) return template(this.buyMultiplePic, this.language.SHEIN_KEY_PC_31391)
      if (!this.getEstimatedInfo?.isSatisfied && this.getEstimatedInfo?.value && !this.isAbCoexist) return ''
      return this.language.SHEIN_KEY_PC_26196
    },
    finalBenefitTitle() {
      const isMiddleastNew = this.screenAbtConfig?.middleast?.p?.middleast == 'new'
      if(this.hasComplianceMode){
        return this.couponLanguage.SHEIN_KEY_PC_30541
      }else if(isMiddleastNew){
        return this.isAb ? (this.couponLanguage.SHEIN_KEY_PC_32261 || `price with special coupon`) : this.couponLanguage.SHEIN_KEY_PC_31956
      }else {
        return this.couponLanguage.SHEIN_KEY_PC_26197
      }
    }
  },
  watch: {
    'DETAIL_STREAM_PART.FIRST': {
      immediate: true,
      handler(val) {
        if (val) {
          this.initPromotion()
        }
      }
    },
    async viewPromotion(val){

      if (val && !this.isShowDrawerAsync) {
        this.isShowDrawerAsync = true
        await this.$nextTick()
      }

      this.isShowDrawer = val
    },
    isShowDrawer(val){
      this.assignState({ viewPromotion: val })
    },
    mallCode() {
      this.initPromotion()
      this.exposeAnalysis()
    },
    isLoginChange() {
      this.isLogin = true
      this.updateEstimatedCouponList()
      this.initPromotion()
    },
    isLogin(val) {
      if (val) {
        if (this.isGetAgain) {
          this.reLoginAndClick()
          this.isGetAgain = false
        }
        if(this.isAddmoreAgain){
          this.addMoreHandle()
          this.isAddmoreAgain = false
        }
      }
    },
    getEstimatedInfo(){
      this.initTimer()
    },
    promotionInfoDesc(){
      this.initTimer()
    }
  },
  mounted() {
    this.initTimer()
    this.getApolloConfig()
    this.exposeAnalysis()
  },
  methods: {
    ...mapMutations(['assignState']),
    ...mapActions(['showLoginModal', 'updateEstimatedCouponList', 'autoGetCouponAtAddBag', 'getSkcEstimatedInfo']),
    souceFormat,
    jumpToAdd: PromotionUtils.jumpToAdd,
    htmlDecode,
    template,
    clickTotalDiscount(){
      this.isExpandTotalDiscount = !this.isExpandTotalDiscount
    },
    expandMorePayment(){
      this.isExpandPayment = !this.isExpandPayment
    },
    paymentTextHandle(item, withIcon) {
      if(!item.rule) return ''
      const { min, random_min, random_max, value, value_amount, type } = item.rule || {}
      const hasThreshold = min?.amount > 0
      const txtOrIcon = withIcon && item?.icon ? `<img class="payment-icon" src=${item?.icon} />` : item?.title
      let text = ''
      if(hasThreshold){
        if(type == 2){ // 如果是随机立减
          text = template(random_min?.amountWithSymbol, random_max?.amountWithSymbol, min?.amountWithSymbol, txtOrIcon, this.language.SHEIN_KEY_PC_30349)
        } else{
          // 具体折扣面额/折扣比例
          const saveVal = type == 0 ? value_amount?.amountWithSymbol : `${(value * 100).toFixed(0)}%`
          const languageRes = item.isBestPay ? this.language.SHEIN_KEY_PC_30353 : this.language.SHEIN_KEY_PC_30348
          text = template(saveVal, min?.amountWithSymbol, txtOrIcon, languageRes)
        }
      } else{
        if(type == 2){ // 如果是随机立减
          text = template(random_min?.amountWithSymbol, random_max?.amountWithSymbol, txtOrIcon, this.language.SHEIN_KEY_PC_30346)
        } else{
          // 具体折扣面额/折扣比例
          const saveVal = type == 0 ? value_amount?.amountWithSymbol : `${(value * 100).toFixed(0)}%`
          const languageRes = item.isBestPay ? this.language.SHEIN_KEY_PC_30351 : this.language.SHEIN_KEY_PC_30344
          text = template(saveVal, txtOrIcon, languageRes)
        }
      }
      return parsePercentText(text?.replace('{99}', txtOrIcon)) // 产品 + app强诉求，要求把title的坑位 改成了${99}，导致web需要额外替换。
    },
    async getApolloConfig() {
      const res = await schttp({
        method: 'POST',
        url: '/api/config/apollo/get',
        data: { apolloKeys: 'IS_USE_NEW_ADD_MORE' }
      })
      const { IS_USE_NEW_ADD_MORE } = res?.info || {}
      this.assignState({
        isUseNewAddMore: IS_USE_NEW_ADD_MORE
      })
    },
    initTimer () {
      if(!this.isShowCountdown) return
      // 倒计时开始时间
      this.countTimer = new CountDown()
      this.beginTime = this.bestEndTime * 1000 - new Date().getTime()
      if (this.beginTime <= 0) {
        this.beginTime == 0
      }

      // 初始化倒计时
      this.countTimer.start({
        seconds: this.beginTime / 1000,
        endFunc: debounce({
          func: () => {
            location.reload()
          }
        })
      })
    },
    percentFormat: function (num) {
      return num + '%'
    },
    couponSubTitle(item) {
      const min = item.coupon_rule[0]?.min?.amountWithSymbol || ''
      const max = item.coupon_rule[0]?.max?.amountWithSymbol || ''
      if (max) {
        return ' ' + this.template(
          min,
          max,
          this.htmlDecode({
            text: this.couponLanguage.SHEIN_KEY_PC_19368,
          })
        )
      }
      if(item.coupon_rule[0]?.min?.amount > 0){
        return ' ' + this.template(
          min,
          this.htmlDecode({ text: this.couponLanguage.SHEIN_KEY_PC_26542 })
        )
      }else{
        return ''
      }
    },
    openCouponDrawer() {
      const promotionItem = this.promotionInfoDesc?.[0] || {}
      const { typeId, rangeList, scId  } = promotionItem
      const isPerSave = this.isPerSave(promotionItem)
      const isMultiPromotion = rangeList?.length && !isPerSave
      if(this.onlyOnePromotion && !this.isBrandFlash && ([10, 30].includes(Number(typeId || 0)) || scId ) && !isMultiPromotion){
        this.jumpToAdd(promotionItem)
        return
      }

      if (!this.isShowDrawerAsync) {
        this.isShowDrawerAsync = true
      }

      this.$nextTick(() => {
        this.isShowDrawer = true
        if (this.exposeItemAnalysisInstance) {
          this.exposeItemAnalysisInstance.update('productIntroCouponListExpose')
        } else {
          this.exposeItemAnalysis()
        }
      })
    },
    loginHandle(cb = () => {}) {
      this.showLoginModal({
        type: 'detail_coupon',
        originRef: this,
        callback: cb
      })
    },
    getCouponHandle(param) {
      this.updateEstimatedCouponList()
      daEventCenter.triggerNotice({
        daId: '1-8-6-92',
        extraData: {
          coupon_id: param.code,
          coupon_tp: param.status,
          status: param.result ? 'success' : 'failure',
          abtest: this.estimatedAbtest
        }
      })
    },
    exposeAnalysis() {
      this.$nextTick(() => {
        if (this.isShowPromo && this.activityMergeCouponDataList.length) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-97',
          })
        }
      })
    },
    exposeItemAnalysis() {
      this.exposeItemAnalysisInstance = daEventCenter.getExposeInstance()
      this.exposeItemAnalysisInstance.subscribe({
        keycode: 'productIntroCouponList`1-8-6-91',
        code: 'productIntroCouponListExpose',
      })
    },
    CopyCode(code){
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        this.$message({
          message: template(code, this.couponLanguage.SHEIN_KEY_PC_26199),
          type: 'success',
        })
        clipboard.destroy()
      })
    },
    reLoginAndClick(cb) {
      if(this.isAllowAutoClaim && !this.isLogin){
        this.isGetAgain = true
        this.isAddmoreAgain = false
        this.loginHandle(cb)
        return
      }
    },
    addBagSuccess(data){
      let isAddSuccess = data?.code == 0
      if(this.isAllowAutoClaim){
        if (!this.isLogin) { // 判断是否登录
          this.isGetAgain = true
          this.isAddmoreAgain = false
          this.loginHandle()
          return
        }        
        this.autoGetCouponAtAddBag({ needToast: isAddSuccess, needToastFail: true })
      }
      this.isShowDrawer = false
    },
    addMoreHandle() {
      const {
        apply_for,
        category_name = [],
        coupon,
        coupon_type_id,
        id,
        coupon_dimension
      } = this.souceFormat(this.optimalCoupon) || {}
      const isToPage = coupon_dimension == 1 && (apply_for == 2 || apply_for == 3) && (coupon_type_id == 2 || coupon_type_id == 5)
      const isToDrawer = this.isUseNewAddMore || (coupon_dimension == 1 && apply_for == 1 && coupon_type_id == 5)
      if(isToDrawer){ // 平台券-券适用范围为全部商品的应付价折扣的平台券
        // 唤起凑单弹窗 
        this.addMoreDialogVisible = true
      }else if(isToPage){ // 平台券-原价折扣券/应付折扣券 -部分商品券 || 平台券-原价折扣券/应付折扣券-部分品类券
        // 跳转凑单页
        const category_id = category_name.map(item => item.cat_id)
        const coupon_type = 1
        const catIds = (category_id && category_id.join(',')) || ''
        const showTip = coupon_type_id == '2' ? 1 : 0
        let query = ''
        switch (apply_for) {
          case '2':
            // 打开可用商品弹窗, 去页面内请求数据
            if (!this.isLogin) { // 判断是否登录
              this.isAddmoreAgain = true
              this.isGetAgain = false
              this.loginHandle()
              return
            } 
            this.showProductModal(this.souceFormat(this.optimalCoupon))
            break
          case '3':
            // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
            // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
            query = `ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`
            sessionStorage.removeItem('couponTipClose') // 删除关闭优惠券tip的标识
            window.open(`/coupon-product.html?${query}`, '_blank')
            break
          default:
        }
      }
    },
    showProductModal(coupon) {
      this.isProductsModalShow = true
      this.selectedCoupon = coupon
      this.getPromProducts(coupon.coupon)
    },
    popQuickView(info, index) {
      let self = this
      const mainGoodsId = this.productIntroData?.detail?.goods_id
      getQuickView().then((quickView) => {
        quickView.show({
          goodsId: info.goods_id,
          mainGoodsId,
          analysis: {
            index,
            pageType: 'cart',
            sa: {
              activity_from: 'coupon',
            },
          },
          callback: {
            handleOpenLogin: (addToWish) => {
              quickView.hide()
              this.showLoginModal({
                type: 'qv-add-wish',
                originRef: {
                  addToWish
                }
              })
            },
            handleAddToCartDone(res){
              window.cart_module && window.cart_module.recart(res)
              self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == info.goods_id })
            },
          },
        })
      })

    },
    async getPromProducts(couponCode) {
      this.isProFetching = true
      const res = await schttp({
        method: 'POST',
        url: '/api/user/coupon/promProducts/get',
        data: { couponCode },
      })
      this.isProFetching = false
      if (res.code === 0) {
        this.promotion = {
          items: res.info.couponSkcList[0].skc,
          ...this.selectedCoupon,
        }
      } else {
        this.isProFetchError = true
      }
    },
    async initPromotion() {
      let skcPromotionInfo = this.skcPromotionInfo 
      let skuAllInfo = this.sizeComposeData.skuMapCompose.skuAllInfoMap
      let promotionInfo = cloneDeep(skcPromotionInfo)
      if (skuAllInfo && Object.keys(skuAllInfo)?.length) {
        let temp1 = Object.values(skuAllInfo).map(item => item.promotionInfo)
        let skuPromotionInfo = []
        for (let i of temp1) {
          for (let j of i) {
            if (!skuPromotionInfo.find(item => item.id === j.id )) {
              skuPromotionInfo.push(j)
            }
          }
        }
        skuPromotionInfo.forEach(item => {
          if (!promotionInfo.find(item2 => item2.id === item.id)) promotionInfo.push(item)
        })
      }
      if (promotionInfo?.length) {
        const { goods_id, goods_sn } = this.productIntroData.detail
        const { items } = promotionCenter.assignItemsSync({
          items: [
            {
              ...this.productIntroData.detail.mall[this.mallCode],
              promotionInfo,
              goods_id,
              goods_sn,
            },
          ],
          language: this.couponLanguage,
          scence: 'detail',
          isGetVipPrice: true,
          caller: 'GoodsDetail',
          isGetNewFlashPromotion: true,
          promotionInfoFromServer: this.promotionInfoFromServer
        })

        const promotionData = items[0].promotionModule.rawModule

        this.assignState({
          promotionData,
          // exclusivePromotionPrice: items[0].vipPrice || {},
          // newFlashPromotion: items[0].newFlashPromotion || null,
          promotionLoaded: true
        })
        this.getSkcEstimatedInfo()
      } else {
        this.assignState({
          exclusivePromotionPrice: {},
          promotionData: [],
          promotionLoaded: true
        })
      }
    },
    promotionInfoTextSel(item) {
      const { flashType, couponLanguage } = this
      const { SHEIN_KEY_PC_27676: PRICE_DROP = 'Limited-Time Price Drop' } = couponLanguage
      const dealerFallDownId = [31]
      const filterID = [10, 11, 24]
      const isLabel = filterID.includes(Number(item.typeId))
      if (item.typeId == 10 && [1, 2, 3, 4, 5, 6].includes(Number(flashType))) {
        if (this.isBrandFlash) return couponLanguage.SHEIN_KEY_PC_29165
        const normalFlashStyle = [1, 5].includes(flashType) || ([4].includes(flashType) && this.newFlashPromotion)
        return normalFlashStyle ? couponLanguage.SHEIN_KEY_PC_26729 : (flashType == 2
          ? couponLanguage.SHEIN_KEY_PC_23050
          : couponLanguage.SHEIN_KEY_PC_23051)
      } else if (isLabel) {
        return item.lable
      } else if (dealerFallDownId.includes(Number(item.typeId)) && item.promotionLogoType == 3) {
        // 命中商家直降活动: typeId = 31 + promotionLogoType 为 3
        return PRICE_DROP
      }
      return item?.tips?.text || ''
    },
    // 促销弹窗加车按钮曝光
    getCartExpose() {
      let threshold
      if (this.getEstimatedInfo) {
        if (this.getEstimatedInfo?.isAb) {
          threshold = 3
        } else if (this.getEstimatedInfo?.isSatisfiedBuyMultiple) {
          threshold = 6
        } else if (this.isAbCoexist) {
          threshold = this.optimalCoupon?.satisfied ? 4 : 5
        } else {
          threshold = this.optimalCoupon?.satisfied ? 1 : 2
        }
      }
      return {
        id: '1-6-1-157',
        data: {
          threshold
        },
      }
    },
    getAddmoreAnalysis(id) {
      return {
        id,
        data: {
          type: this.optimalCoupon?.rule_dimension == '5' ? 1 : 0,
          id: this.optimalCouponCode
        }
      }
    },
    getCopyAnalysis(id, code){
      return {
        id,
        data: {
          location: 1,
          id: code
        }
      }
    },
    getEntryAnalysis(id) {
      const bestPromo = this.promotionInfoDesc[0] || {}
      const singleNum = (bestPromo.typeId == 24 && bestPromo.singleNum) || '',
            orderNum = (bestPromo.typeId == 24 && bestPromo.orderNum) || '',
            ruleType = (bestPromo.typeId == 24 && bestPromo.ruleType) || '',
            promotion_type_id = bestPromo.typeId || '',
            promotion_id = bestPromo.id || '',
            is_brand = (this.brandDetail && this.brandDetail.name) ? 1 : 0,
            brand_code = (this.brandDetail && this.brandDetail.brand_code) || ''
      const promotion_logo_type = bestPromo?.promotionLogoType || ''
      const param = {
        promotion_type_id,
        promotion_logo_type,
        promotion_id,
        is_brand,
        brand_code,
        location: 'detail'
      }

      // 在新位置时，如果用新样式 则上报1， 原样式上报0 未移动的旧位置不需要上报
      if (!this.isAb) {
        param.Promotioncolor = 1
      }
      if(ruleType) {
        let type = ruleType == 'no_discount' ? 'nosale' : 'sale',
            single = Number(singleNum) ? '_item' : '',
            order = Number(orderNum) && ruleType != 'no_discount' ? '_promotion' : Number(orderNum) && ruleType == 'no_discount' ? '_collection' : ''
        param.type = type + single + order
      }
      const couponStr = this.currCouponList.map(item => {
        return item.coupon_code || item.coupon
      }).join('|')

      if (promotion_type_id == 14) {
        param.activity_type = [2, 4].includes(Number(bestPromo?.rules?.[0].type)) ? 0 : 1
      }
      const payment_code = this.drawerPaymentInfos?.map((it, idx) => ((idx === 0 && 'isBestPay' in it) ? '1-' : '') + it.paymentCode).join(',')
      const paymenttype = this.drawerPaymentInfos?.map((it, idx) => ((idx === 0 && 'isBestPay' in it) ? '1-' : '') + it.rule?.type).join(',')
      return {
        id,
        data: {
          coupon: couponStr,
          payment_code,
          paymenttype,
          ...param
        }
      }
    },
    getCouponAnalysis(item) {
      return {
        id: '1-8-6-91',
        data: {
          coupon_id: item.coupon,
          coupon_tp: item.coupon_status,
          couponStatus: item.apply_for == 101 ? 5 : (item.is_bind == 0 ? 4 : item.coupon_status), //1、已领取;2、未领取;4、非绑定券不可领取;5 ab 价格
          status: '-',
          abtest: this.estimatedAbtest
        }
      }
    },
    handleChangeColor (color) {
      if (color.goods_id == this.productIntroData.detail.goods_id) return
      this.$emit('switchColor', color)
    },
    couponItemThemeConfig(coupon) {
      if(!['5', '6', '9'].includes(coupon.apply_for) && coupon.real_type_id == '55'){ // 超省卡的满折券和满减券，需要渲染成玫红色
        return couponThemeConfig.superSaveTheme
      }
      return ['5', '6', '9'].includes(coupon.apply_for) ? couponThemeConfig.greenTheme : null
    },
    // 每满减
    isPerSave(promotionDescItem) {
      let { rules, typeId } = promotionDescItem
      return typeId == 14 && [2, 4].includes(Number(rules?.[0]?.type))
    },
    showCoupondown(item) {
      if (item.apply_for == 101) return false
      return this.E_Time_Limit === 'Show' || this.E_Time_Limit === 'Nothrodshow'
    },
    showOptimalCouponItem(item) {
      if (this.isAbCoexist && !this.getEstimatedInfo?.isSatisfied) {
        return !!item.satisfied
      }
      return true
    }
  },
  emits: ['switchColor'],
}
</script>

<style lang="less">
@import './style/couponDialogue.less';
.product-intro {
  &__text {
    box-sizing: content-box;
    padding: 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #c44a01;
    font-size: 11px;
    line-height: 14px;
    .promotion-divide-icon{
      font-weight:bold;
      margin: 0 5px;
    }
  }
  &__promotion-drawer{
    .sui-drawer__title {
      padding: 32px 0 24px;
      font-weight: 900;
    }
    .sui-drawer__body{
      padding: 0 24px 24px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__head-coupon-new {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center
  }
  &__icon-wrap {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-left: 8px;
    background: rgba(250, 99, 56, 0.2); 
    border-radius: 100%;
    color: #c44a01;
    /*rw:begin*/
    color: #fc4070;
    background: rgba(252, 64, 112, 0.10);
  }
  &__icon {
    &.reverse {
      transform: rotateY(180deg);
    }
  }
  &__couponDrawerNew{
    .coupon-item-new:last-child{
      /* stylelint-disable-next-line selector-max-specificity */
      .coupon-item-wrapper{
        margin-bottom: 0;
      }
    }
  }
}
.promotion-container {
  overflow: auto;
  .transfrom-arrow{
    transform: rotate(180deg)
  }
  &.estimated-experiment{
    .promotion-container__drawer-h{
      padding: 0;
    }
    .promotion-container__drawer-promo{
      color: #FA6338;
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      align-items: center;
      svg {
        color: #959595;
        font-weight:bold
      }
    }
    .promotion-container__drawer-content{
      padding-right: 0;
    }
    .promotion-container__drawer-multi{
      color: #666;
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      width: 100%;
      span {
        color: #FA6338;
      }
    }
  }
  &__drawer {
    position: relative;
    margin-bottom: 24px;
  }
  &__drawer-activity {
    .flexbox();
    .space-between();
    align-items: flex-start;
  }
  &__drawer-payment{
    border-bottom: 1px solid #E5E5E5;
  }
  &__payment-tip{
    color: #FA6338;
    font-size: 16px;
    margin: 10px 0;
  }
  &__payment-item{
    color: #666;
    font-size: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .payment-icon{
      width: 21px;
      max-height: 14px;
      margin-left: 2px;
    }
  }
  &__payment-arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #959595;
    margin-bottom: 10px;
    i {
      width: 16px;
      height: 16px
    }
    .transfrom-arrow{
      transform: rotate(180deg)
    }
  }
  &__drawer-rt {
    flex: 1;
    overflow: hidden;
  }
  &__drawer-h {
    margin-bottom: 8px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: bold;
  }
  &__drawer-title {
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    color: #999;
    .icon-close {
      position: absolute;
      .right(2px);
      top: 2px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: inline-block;
      text-align: center;
      color: #666;
    }
  }
  &__drawer-promotion {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: left;
    text-decoration: none;
    min-height: auto;
    color: @sui_color_promo_dark;
  }
  &__drawer-item {
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
    .flexbox();
    .space-between();
    .align-center();
    .sui_icon_more_right_14px_1 {
      color: #959595;
    }
    &:hover{
      text-decoration: none;
    }
  }
  &__drawer-single {
    height: auto;
    position: relative;
    display: block;
    box-sizing: content-box;
    width: 100%;
    line-height: normal;
    padding-bottom:4px;
    // .promotion-container__drawer-icon {
    //   margin-top: 0.12px;
    //   .margin-r(9px);
    //   &.icon-Rectangle-pathx1 {
    //     color: @sui_color_promo;
    //   }
    // }
    + .sui_icon_more_right_14px_1 {
      .margin-r(0);
    }
  }
  &__drawer-promo {
    .margin-l(0);
    .padding-r(15px);
    font-size: 16px;
    position: relative;
    margin-top: 12px;
    margin-bottom: 8px;
    .txt-l();
    color: @sui_color_promo_dark;
  }
  &__drawer-content {
    .padding-r(15px);
    font-size: 14px;
    line-height: 17px;
    overflow: hidden;
    font-weight: bold;
    color: @sui_color_promo_dark;
    .swiper-slide {
      .text-overflow();
    }
  }
  &__drawer-tips {
    font-size: 14px;
    margin-bottom: 8px;
    .padding-r(15px);
    .txt-l();
    font-weight: normal;
    color: #666;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__drawer-multi {
    color: #666;
    .flexbox();
    .padding-r(15px);
    margin: 15px 0;
    line-height: 17px;
    font-weight: normal /*rw:normal*/;
    font-size: 16px;
    span {
      color: @sui_color_promo_dark;
    }
    + .sui_icon_more_right_14px_1 {
      position: relative;
      .right(0);
      .margin-r(0);
    }
  }
  &__left {
    .left(0);
  }
  &__drawer-difftip {
    color: #959595;
    font-weight: normal;
    font-size: 12px;
  }
  &__cal {
    font-family: "Arial";
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__cal-lowest {
    font-size: 14px;
    margin: 0 0 20px 0;
    display: flex;
    justify-content: center;
    text-align: center;
    .lowest-price-wrap {
      padding: 6px 12px;
      background-color: #fff6f3;
      color: #fa6338;
    }
    .lowest-price-num{
      font-size: 17px;
      font-weight: 700;
    }
    .countdown-time-tips{
      display: flex;
      align-items: center;
      margin-left: 2px;
      span {
        width: 18px;
        height: 18px;
        line-height: 18px;
        color: #FA6338;
        background-color: #fff;
        text-align: center;
        margin: 0 2px;
        border-radius: 2px;
        /*rw:begin*/
        // background-color: #222222;
      }
    }
  }
  &__countdown-area{
    font-size: 12px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__cal-detail {
    width: 100%;
    padding: 12px 10px;
    background: #f6f6f6;
    position: relative;
    word-break: break-word;
    &.has-total-discount{
      padding-right: 26px;
    }
    &.have-threshold {
      &::after {
        content: "";
        position: absolute;
        top: -12px; 
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid #f6f6f6;
      }
    }
    .num-bold {
      font-weight: 700;
      white-space: nowrap;
    }
    .num-lighter {
      font-family: SF Pro;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      text-align: right;
      margin-top: 2px;
      color: #666;
    }
    .right-num {
      padding-left:12px;
      box-sizing: content-box;
      text-align: right;
    }
    .total-discount{
      position: relative;
      .sui-icon-common__wrap {
        position: absolute;
        right: -16px;
        top: 2px;
      }
      svg {
        cursor: pointer;
        color: #959595;
      }
    }
    .benefits-tip {
      color: #666666;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .by-total-expand{
      .price-from {
        color: #767676;
        font-size: 12px;
        padding-left: 8px;
        margin-bottom: 6px;
      }
    }
    .de-lowest-price{
      font-size: 11px;
      color: #666666;
      margin-bottom: 12px;
      margin-top: 6px;
    }
    .price-from {
      color: #222;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-weight: 700;
      &.de-show-lowest{
        margin-bottom: 0;
      }
      &.for-expand{
        margin-bottom: 6px;
      }
      &.current-price{
        border-bottom: 1px #bbb dashed;
        padding-bottom: 12px;
        margin-top: 12px;
      }
      .price-type {
        text-align: left;
      }
      .origin-price {
        display: none;
        font-size: 11px;
        line-height: 14px;
        color: #666666;
        min-width: 110px;
        text-align: right;
        font-weight: 400
      }
    }
    .hasActivity {
      color: #fa6338;
      text-align: right;
      .origin-price {
        display: inline-block;
      }
    }
    .saved-money {
      color: #fa6338;
      font-size: 14px;
      margin-bottom: 12px;
      .savedMoney-item-wrap{
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .left-tips{
        font-weight: 700;
      }
      .copy-code-wrap {
        margin-top: 4px;
        font-size: 11px;
        line-height: 14px;
      }
      .copy-code-tip {
        color: #767676;
      }
      .copy-code-btn {
        color: #2d68a8;
        cursor: pointer;
      }
    }
    .final-price {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #222;
      padding-top: 12px;
      border-top: 1px #bbb dashed;
      font-weight: 700;
    }
    .finalPrice-discount-wrap{
      display: flex;
    }
    .final-price-discount {
      display: inline-block;
      padding: 1px 2px;
      border: 1px solid #FA6338;
      border-radius: 2px;
      color: #FA6338;
      font-size: 10px;
      line-height: 12px;
      vertical-align: middle;
      margin-left: 4px;
    }
  }
  &__add-cart {
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    background: #222222;
    margin-bottom: 8px;
    cursor: pointer;
  }
  &__cal-disclaimers {
    font-size: 11px;
    color: #959595;
    line-height: normal;
  }
  &__addMore {
    width: 100%;
    padding: 12px 10px 12px 10px;
    background: #FFF6F3;
    display: flex;
    justify-content: space-between;
  }
  &__addMore-left {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    & > p > span {/* stylelint-disable-line selector-max-type */
      color: #FA6338;
      font-weight: 600;
    }
  }

  &__addMore-left_textRight {
    display: flex;
    flex-wrap: wrap;
  }
  &__addMore-leftCount {
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: #FA6338;
  }
  &__addMore-leftCountIcon {
    font-size: 11px;
    margin-right: 3px;
    transform: translateY(-1px);
  }
  &__addMore-right {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 24px;
    padding: 6px 8px 5px 8px;
    background: #FA6338;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    margin-left: 8px;
    white-space: nowrap;
  }
  &__satisfiedBuyMultipleTitle {
    padding: 6px 12px;
    background: #FFF6F3;
    color: #FA6338;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  &__satisfiedBuyMultipleTitle-price {
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    line-height: 19.55px;
    text-align: left;
  }
  &__satisfiedBuyMultipleTitle-text {
    font-family: SF Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
  }
}
</style>
