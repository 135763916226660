<template>
  <LazyHydrationWrapper :when-triggered="DETAIL_STREAM_PART.FOURTH">
    <CommonHeader
      v-if="headerVueSsr"
      :context="bsLibsEnvs"
    />
  </LazyHydrationWrapper>
  <div
    class="goods-detailv2"
    @click="hideSelection"
  >
    <!-- 'goods-detailv2__media-small': showSmallMedia 主图自适应 -->
    <div
      class="goods-detailv2__media"
      :class="{
        'goods-detailv2__media-expand':
          pageComponents.productIntroGalleryConf.isRwGallery,
      }"
    >
      <!-- SSR -->
      <div class="goods-detailv2__media-inner">
        <!-- BreadCrumb -->
        <BreadCrumb />
        <!-- ProductIntro -->
        <ProductIntro />
      </div>
    </div>

    <ClientOnly>
      <div
        v-if="DETAIL_STREAM_PART.FOURTH"
        class="goods-detailv2__1200"
      >
        <DetailsPictures v-if="showDetailsImages" />
        <NewOutfitModule v-if="showOutfitLookModule" />
        <ComboBuy
          v-if="comboBuyAbtConfig.showComboBuy"
          :show-combo-buy="comboBuyAbtConfig.showComboBuy"
          :is-new-style="comboBuyAbtConfig.isShowNewComboBuyStyleAbt"
          :is-screen-style="comboBuyAbtConfig.isShowComboBuyScreenStyleAbt"
        />
        <StyleGalleryInfo
          v-if="showStyleGalleryInfo"
          :goods-sn="goodsSn"
        />
        <CustomerReviews v-if="!isStickyStyle" />
        <Recommend />
        <CCCBanner />
      </div>
    </ClientOnly>
    <!-- tips -->
    <ClientOnly v-if="tipModal.show">
      <modal-vue
        :twobtn="tipModal.twobtn"
        :show="tipModal.show"
        @ok="hideTipModal"
        @cancel="hideTipModal"
      >
        <template #title>
          <div
            :class="{ redfont: tipModal.type === 'payError' }"
            v-html="tipModal.text"
          ></div>
        </template>
      </modal-vue>
    </ClientOnly>

    <!-- 折扣促销 超过限制订单数-->
    <ClientOnly v-if="promotionLimitShow">
      <modal-vue
        :show="promotionLimitShow"
        class="goods-detailv2__promotion"
        @cancel="closeLimitTips"
      >
        <div class="limitTips-wrap">
          <p class="tip">
            {{ language.SHEIN_KEY_PC_17494 }}
          </p>
          <button
            class="she-btn-black"
            da-event-click="1-8-6-42"
            @click="goToBag"
          >
            {{ language.SHEIN_KEY_PC_17502 }}
          </button>
          <button
            class="she-btn-white"
            @click="closeLimitTips"
          >
            {{ language.SHEIN_KEY_PC_15278 }}
          </button>
        </div>
        <template #footer>
          <div></div>
        </template>
      </modal-vue>
    </ClientOnly>
    <ClientOnly>
      <ProductIntroFloorPrice v-if="isDetailBuyNowPriceCeiling" />
    </ClientOnly>
    <ProductIntroAgeLimit />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getQueryString } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import mitt from 'mitt'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import { getPreFetchDetailRealTimeData } from 'public/src/services/pre_requests/detail'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import ProductIntro from './components/ProductIntro'
import CommonHeader from 'public/src/pages/common/common-header/common-header.vue'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'

daEventCenter.addSubscriber({ modulecode: '1-8-4' })
daEventCenter.addSubscriber({ modulecode: '1-8-6' })
const { IS_RW } = gbCommonInfo
export default {
  name: 'App',
  components: {
    LazyHydrationWrapper,
    ClientOnly,
    CommonHeader,
    CCCBanner: defineAsyncComponent(() => import('./components/CCCBanner')),
    BreadCrumb: defineAsyncComponent(() => import('./components/BreadCrumb')),
    // GetTheLook: defineAsyncComponent(() => import('./components/GetTheLook')),
    NewOutfitModule: defineAsyncComponent(() =>
      import(
        './components/RecommendRelatives/GetTheLookNew/NewOutfitModule.vue'
      )
    ),
    ProductIntro,
    CustomerReviews: defineAsyncComponent(() =>
      import('./components/CustomerReviews')
    ),
    ComboBuy: defineAsyncComponent(() =>
      import('./components/ComboBuy/ComboBuyIndex')
    ),
    StyleGalleryInfo: defineAsyncComponent(() =>
      import('./components/StyleGalleryInfo')
    ),
    DetailsPictures: defineAsyncComponent(() =>
      import('./components/DetailsPictures')
    ),
    Recommend: defineAsyncComponent(() => import('./components/Recommend')),
    ProductIntroAgeLimit: defineAsyncComponent(() =>
      import('./components/ProductIntroAgeLimit')
    ),
    ProductIntroFloorPrice: defineAsyncComponent(() =>
      import('./components/ProductIntroFloorPrice')
    )
  },
  data() {
    return {
      breadcrumbList: null,
      isNewStyleGallery: false
    }
  },
  computed: {
    ...mapState([
      'loading',
      'productIntroData',
      'currentGoodsId',
      'promotionLimitShow',
      'language',
      'isSelectHide',
      'pageComponents',
      'skuInfo',
      'attribute',
      'mainAttribute',
      'defaultMallCode',
      'URL_PATH',
      'headerVueSsr',
      'bsLibsEnvs',
      'DETAIL_STREAM_PART'
    ]),
    ...mapGetters([
      'screenAbtConfig',
      'goodsSn',
      'tipModal',
      'loginModal',
      'pageComponents',
      'mallCode',
      'hasLocalSize',
      'pageStructureAbtStyleInfo',
      'initSchemaSEO',
      'picSizeGuideInfo',
      'picInterestInfo',
    ]),
    ...mapGetters('AddBtn', ['isDetailBuyNowPriceCeiling']),
    ...mapGetters('Recommend', ['showOutfitLookModule', 'comboBuyAbtConfig']),
    showDetailsImages() {
      const { moreDetailConf = {} } = this.pageComponents
      return !moreDetailConf.notShow && moreDetailConf.list.length
    },
    showStyleGalleryInfo() {
      const { styleGallery } = this.productIntroData
      return styleGallery?.imgTotal
    },
    // showSmallMedia() {
    //   return (
    //     !IS_RW &&
    //     this.screenAbtConfig?.imagearea?.param?.imagearea === 'small' &&
    //     !this.pageComponents?.productIntroGalleryConf?.isRwGallery
    //   )
    // },
    isStickyStyle() {
      return this.pageStructureAbtStyleInfo.isNew
    }
  },
  watch: {
    loading(newValue) {
      if (newValue) {
        // 发送 Load
        const price = this.productIntroData?.detail?.mall?.[this.mallCode] || {}
        daEventCenter.triggerNotice({
          daId: '1-8-4-2',
          extraData: {
            id: this.productIntroData.detail.goods_id,
            sku: this.productIntroData.detail.goods_sn,
            spu: this.productIntroData.detail.productRelationID,
            category: this.productIntroData.detail.cat_id,
            price: price.salePrice?.amount,
            usd_price: price.salePrice?.usdAmount,
            discount: price.unit_discount,
            img: this.productIntroData.detail.goods_img,
            url_name: this.productIntroData.detail.goods_url_name,
            currency: gbCommonInfo.currency,
            color_sum: this.productIntroData.relation_color.length + 1
          }
        })

        const traceid = window.gbExposeTraceid('getProduct', {
          goods_id: this.currentGoodsId
        })

        window.SaPageInfo.page_param.goods_id = this.currentGoodsId
        window.SaPageInfo.page_param.attribute_list = ''
        window.SaPageInfo.page_param.mainattr = this.mainAttribute
        window.SaPageInfo.page_param.mall_code = this.mallCode

        window.SaPageInfo.start_time = Date.now()
        window.SaPageInfo.page_param.is_default_mall =
          this.defaultMallCode == this.mallCode ? 1 : 0
        window.SaPageInfo.page_param.is_mall_stock =
          this.productIntroData?.detail?.mall?.[this.mallCode]?.stock > 0
            ? 1
            : 0
        if (traceid) {
          window.SaPageInfo.page_param.traceid = traceid
        }
        window.SaPageInfo.page_param.is_show_pictureinterest = this.picInterestInfo?.interestList?.length ? 1 : 0
        window.SaPageInfo.page_param.is_show_sizetab = this.picSizeGuideInfo?.imgUrl ? 1 : 0
        SaPageInfo.page_param.is_include_video = this.productIntroData?.goods_imgs?.video_url ? 1 : 0
        SaPageInfo.page_param.is_switch_skc = this.mainAttribute ? 1 : 0
        // 商详曝光埋点 page_goods_detail 增加页面属性 lowstock_type
        const { is_absolute_low_inventory, is_relatively_low_inventory } = this.productIntroData?.detail?.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
        SaPageInfo.page_param.lowstock_type = is_absolute_low_inventory === true ? 1 : is_relatively_low_inventory === true ? 2 : 0
        // 更新图片浏览埋点信息
        this.updateImageViewInfo()
        sa('set', 'setPageData', window.SaPageInfo)
        sa('send', 'pageEnter', {
          start_time: Date.now()
        })

        this.recordPageView()
      }
    },
    skuInfo: {
      deep: true,
      async handler() {
        // 因为目前 PC 数据流太乱，所以暂时在这里进行 SEO 更新吧
        // 正常是等数据回来后更新
        await this.$nextTick()
        this.getRealTimeSchemaSEO()
      }
    },
    $route(to, from) {
      if (to.params.goodsId !== from.params.goodsId) {
        // Vue 3 Route 需指定命名参数
        this.handlePageUpdate(to.meta.currentGoodsId)
        this.getGoodsDetailData(to.meta.currentGoodsId)
        this.assignState({ noResetUnfold: false })
      }
    }
  },
  mounted() {
    getPreFetchDetailRealTimeData().then((data) => {
      if (data) {
        this.initDetailSreamPart(data)
      }
    })
    // 刷新滚动问题
    if (typeof window !== 'undefined' && 'scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
    this.loadedCompleteHandle()
    // this.getBreadcrumbList()
    this.assignState({
      attribute: getQueryString({ key: 'attr_ids' }) || '',
      mainAttribute: getQueryString({ key: 'main_attr' }) || '',
      isAfterSsr: true
    })
    // 服务降级 用到的
    document.querySelector('#product-skeleton')?.style && 
      (document.querySelector('#product-skeleton').style.display = 'none')
    if (
      typeof window !== 'undefined' &&
      typeof SHEIN_W !== 'undefined' &&
      window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD &&
      window?.gbCommonInfo?.WEB_CLIENT === 'shein'
    ) {
      window.appEventCenter = window.appEventCenter || mitt()
      window.appEventCenter?.on?.('detailLoginSucCb', this.loginModalSucCb)
    }
  },
  unmounted() {
    if (
      typeof window !== 'undefined' &&
      typeof SHEIN_W !== 'undefined' &&
      window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD &&
      window?.gbCommonInfo?.WEB_CLIENT === 'shein'
    ) {
      window.appEventCenter = window.appEventCenter || mitt()
      window.appEventCenter?.off?.('detailLoginSucCb', this.loginModalSucCb)
    }
  },
  methods: {
    ...mapActions([
      'getProductIntroData',
      'hideTipModal',
      'hideLoginModal',
      'loginModalSucCb',
      'initDetailSreamPart'
    ]),
    ...mapMutations(['assignState', 'updateDetailStreamPart', 'updateCountry', 'updateSizeGuideCountry', 'updateHeelInfo']),
    /**
     * SSR complete
     */
    async loadedCompleteHandle() {
      this.handlePageEnter(this.$route.meta.currentGoodsId)
      await this.$nextTick()
      Promise.all([
        this.getGoodsFirstImgRatio(),
        this.getInitLocalCountry(),
        this.getHeelHeightRange(),
        this.recordPageView()
      ])
    },
    /**
     * 设置当前 goods id 并请求数据
     */
    getGoodsDetailData(goodsId) {
      this.assignState({
        currentGoodsId: Number(goodsId),
        realTimeReady: false,
      })
      this.getProductIntroData(goodsId)
    },
    /**
     * 页面进入上报信息初始化
     */
    async handlePageEnter(goodsId) {
      const traceid = window.gbExposeTraceid('getProduct', {
        goods_id: goodsId
      })

      if (!window.SaPageInfo.page_param) window.SaPageInfo.page_param = {}

      if (traceid) {
        window.SaPageInfo.page_param.traceid = traceid
      }

      // 页面埋点添加前一个页面带过来的资源信息
      const url_from = getQueryString({ key: 'url_from' })
      SaPageInfo.page_param.src_module = url_from
        ? 'ads'
        : getQueryString({ key: 'src_module' }) || ''
      SaPageInfo.page_param.src_identifier = url_from
        ? `uf=${url_from}`
        : getQueryString({ key: 'src_identifier' }) || ''
      SaPageInfo.page_param.src_tab_page_id =
        getQueryString({ key: 'src_tab_page_id' }) || ''
      SaPageInfo.page_param.attribute_list = this.attribute
      SaPageInfo.page_param.mainattr = this.mainAttribute
      SaPageInfo.page_param.mall_code = this.mallCode
      SaPageInfo.page_param.is_default_mall =
        this.defaultMallCode == this.mallCode ? 1 : 0
      SaPageInfo.page_param.is_mall_stock =
        this.productIntroData?.detail?.mall?.[this.mallCode]?.stock > 0 ? 1 : 0
      SaPageInfo.page_param.is_include_moredetails = this.showDetailsImages
        ? 1
        : 0
      SaPageInfo.page_param.local_size = this.hasLocalSize ? 1 : 0
      if (!IS_RW) {
        SaPageInfo.page_param.is_show_pictureinterest = this.picInterestInfo
          ?.interestList?.length
          ? 1
          : 0
      }
      SaPageInfo.page_param.is_include_video = this.productIntroData?.goods_imgs?.video_url ? 1 : 0
      SaPageInfo.page_param.is_switch_skc = this.mainAttribute ? 1 : 0
      // 商详曝光埋点 page_goods_detail 增加页面属性 lowstock_type
      const { is_absolute_low_inventory, is_relatively_low_inventory } = this.productIntroData?.detail?.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
      SaPageInfo.page_param.lowstock_type = is_absolute_low_inventory === true ? 1 : is_relatively_low_inventory === true ? 2 : 0
      this.updateImageViewInfo()
      sa('set', 'setPageData', window.SaPageInfo)
      // pv提前上报
      sa('send', 'pageEnter', {
        start_time: Date.now()
      })

      // eslint-disable-next-line require-atomic-updates
      // window.SaPageInfo.page_param.abtest =
      //   abtservice.getUserAbtResultForAnalysis({ posKeys: `${posKeys},${newPosKeys}` }).sa

      // const shippingtimeAbtInfo = abtservice.getUserAbtResultForAnalysis({
      //   posKeys: 'Shippingtime'
      // })

      this.assignState({
        detailHotNews: false,
        showGetTheLook: true
      })

      sa('set', 'setPageData', window.SaPageInfo)

      // 绑定退出页面上报
      window.onbeforeunload = function () {
        sa('send', 'pageLeave', { end_time: Date.now() })
      }

      // 发送 Load
      const price = this.productIntroData?.detail?.mall?.[this.mallCode] || {}
      daEventCenter.triggerNotice({
        daId: '1-8-4-2',
        extraData: {
          id: this.productIntroData.detail.goods_id,
          sku: this.productIntroData.detail.goods_sn,
          spu: this.productIntroData.detail.productRelationID,
          category: this.productIntroData.detail.cat_id,
          price: price.salePrice?.amount,
          usd_price: price.salePrice?.usdAmount,
          discount: price.unit_discount,
          img: this.productIntroData.detail.goods_img,
          url_name: this.productIntroData.detail.goods_url_name,
          currency: gbCommonInfo.currency,
          color_sum: this.productIntroData.relation_color.length + 1
        }
      })
    },
    /**
     * 更新页面上报信息
     */
    handlePageUpdate() {
      sa('send', 'pageLeave', {
        // 离开当前页需要上报
        end_time: Date.now()
      })
    },
    getRealTimeSchemaSEO() {
      document.getElementById('goodsDetailSchema').innerHTML =
        this.initSchemaSEO
    },
    goToBag() {
      cart_module.hover(true)
      this.assignState({
        promotionLimitShow: false
      })
    },
    closeLimitTips() {
      this.assignState({
        promotionLimitShow: false
      })
    },
    hideSelection() {
      this.assignState({
        isSelectHide: !this.isSelectHide
      })
    },
    async getHeelHeightRange() {
      // 指定的shein和romwe类目，命中ABT，且含有跟高(Heel Height)属性时，才展示Heel Hight强化
      const sheinRomweCateSet = new Set([
        '1751', '1750', '1748', '3181', '2517', '3182', '3193', '1749', '715', 
        '700', '699', '1981', '1486', '1985', '2065', '749'
      ])
      const { cat_id } = this.productIntroData?.detail ?? ''
      const descriptionList =
        this.pageComponents?.descriptionConf?.descriptionList ?? []
      // attr_id: 45代表跟高属性
      let hasHeelHeight = descriptionList.some(item => item.attr_id == '45')
      let dimensionAttrInfo =
        this.productIntroData?.sizeInfoDes?.dimensionAttrInfo ?? []
      const heelHeightMap = dimensionAttrInfo.find(item => {
        return item?.attr_name_en?.includes('Heel Height')
      })
      let showSizeRange = true // 返回的尺码数据是否本身包含范围，如果有，则不拼接tips展示
      let showHeelHeight = false

      if (!hasHeelHeight) {
        this.updateHeelInfo({
          showHeelHeight,
          heelHeight: '',
          cmInchInfo: ''
        })
        return
      }

      // 处理尺寸范围
      const getSizeInfoRange = (sizeArr = [], separator = '') => {
        const result = []
        sizeArr.forEach(item => {
          Object.keys(item).some(key => {
            if (key.includes(heelHeightMap?.attr_name)) {
              let heelHeight =
                item[key]?.trim()?.split(separator)?.[0]?.trim() || ''
              if (isNaN(heelHeight)) {
                showSizeRange = false
              }
              heelHeight && result.push(heelHeight)
            }
          })
        })
        if (result.length === 0) showSizeRange = false
        let min = Math.min(...result)
        const max = Math.max(...result)
        return (min === max ? min : `${min}-${max}`) + separator
      }
      const sizeInfoRange = getSizeInfoRange(
        this.productIntroData?.sizeInfoDes?.sizeInfo,
        'cm'
      )
      const sizeInfoInchRange = getSizeInfoRange(
        this.productIntroData?.sizeInfoDes?.sizeInfoInch,
        'inch'
      )
      const heelHeight = descriptionList.find(item => item.attr_id == '45')
      // 外露场景heel height
      // const textAttr =
      //   this.pageComponents?.descPosConf?.fPosData?.textAttr ?? []
      // textAttr.forEach(item => {
      //   if (heelHeightMap?.attr_name?.includes(item.name)) {
      //     item.value += ` (${sizeInfoRange}/${sizeInfoInchRange})`
      //   }
      // })
      // 实验值为Heelhight = true时才展示
      if (
        sheinRomweCateSet.has(cat_id) &&
        hasHeelHeight
      ) {
        showSizeRange && (showHeelHeight = true)
      }
      this.updateHeelInfo({
        showHeelHeight,
        heelHeight: `${heelHeight?.attr_value}`,
        cmInchInfo: ` (${sizeInfoRange}/${sizeInfoInchRange})`
      })
    },
    async getInitLocalCountry() {
      const { productIntroData } = this
      const { lang } = gbCommonInfo
      // 优先判断当前站点是否有配置，再根据ip判断
      let country = localStorage.getItem('last_select_country')
      if (country === 'default') return ''
      let defaultlocalsize =
        this.screenAbtConfig?.defaultlocalsize?.p?.defaultlocalsize
      if (
        !country &&
        (defaultlocalsize === 'new' ||
          defaultlocalsize === 'default_size_no_default')
      ) {
        country = ''
      } else if (!country) {
        country = (lang && lang.toUpperCase()) || ''
        const localSizeRules =
          productIntroData?.multiLocalSize?.size_rule_list || {}
        // 判断是否有本地尺码
        if (!localSizeRules[country]) {
          const countryData = await UserInfoManager.get({
            key: 'Country',
            actionType: '/goods_detail_v2/App.vue'
          })
          const pcCountry = countryData?.countryAbbr || ''

          const countryCorrect = {
            GB: 'UK'
          }
          country = countryCorrect[pcCountry] || pcCountry
        }
      }

      this.updateCountry(country)
      this.updateSizeGuideCountry(country)
    },
    // 获取首图宽高比例
    async getGoodsFirstImgRatio() {
      // 取路由上的比例
      let { imgRatio = '' } = this.$route?.query
      // 兼容路径有多个imgRatio参数
      if (Array.isArray(imgRatio)) {
        imgRatio = imgRatio.find(i => i) || ''
      }
      if (!imgRatio) {
        const { goods_imgs } = this.productIntroData
        const firstImg = goods_imgs?.main_image?.origin_image
        if (firstImg) {
          imgRatio = await getImageRatio(firstImg)
        }
      }
      imgRatio && this.assignState({ fixedRatio: imgRatio })
    },
    // 记录用户24小时内访问商详的次数
    recordPageView() {
      const pageViewCookieKey = 'goods_detail_page_view'
      const expire = 1000 * 60 * 60 * 24
      let cacheValue = JSON.parse(localStorage.getItem(pageViewCookieKey))
      if (cacheValue && cacheValue.end && cacheValue.end > Date.now()) {
        // 24小时内访问记录上限
        if (cacheValue.value >= 1000) return
        // 如果有值，并且在有效期范围，则继续累加
        let count = cacheValue.value + 1
        const storageData = JSON.stringify({
          value: count,
          end: cacheValue.end
        })
        localStorage.setItem(pageViewCookieKey, storageData)
      } else {
        // 初次进入或者超过有效期，则置为1
        const storageData = JSON.stringify({
          value: 1,
          end: Date.now() + expire
        })
        localStorage.setItem(pageViewCookieKey, storageData)
      }
    },
    updateImageViewInfo(){
      // 双图默认展示两个，默认值为2， 前两张图不会出现利益点
      const initViewNumber = this.pageComponents?.productIntroGalleryConf?.isRwGallery ? 2 : 1
      SaPageInfo.page_param.img_view_index = initViewNumber
      SaPageInfo.page_param.gds_img_view_cnt = initViewNumber
      SaPageInfo.page_param.is_show_sizetab = this.picSizeGuideInfo?.imgUrl ? 1 : 0
    },
  },
}
</script>

<style lang="less">
.goods-detailv2 {
  padding-bottom: 85px;

  &__media-expand {
    width: 1280px;
  }

  &__1200 {
    .container-fixed();
    max-width: 1200px + 80px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .color-orange-tips {
    color: @sui_color_highlight;
  }

  //share
  .addthis-smartlayers {
    white-space: nowrap;

    .at-share-btn {
      margin: 0 2.5px;
    }
  }

  .mgds-recommend {
    margin-top: 100px;
    .recommend-list-section {
      margin: 0 -5px;
    }
  }

  //推荐
  .c-recommend-goodsd {
    .recommend-title {
      margin-top: 100px;
      margin-bottom: 25px;
      font-family: 'Arial Black';
      /* rw: Adieu */
    }

    .recommend-rank {
      margin-bottom: 0;
    }

    .recommend-bottm {
      font-size: 16px;
    }
  }
}

.cursor-zoom-in {
  cursor: url('/she_dist/images/cursor-enlarge.ico'), auto;
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: url('/she_dist/images/cursor-lessen.ico'), auto;
  cursor: zoom-out;
}

//气泡提示
.common-hovertips {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  z-index: 9; /* stylelint-disable-line declaration-property-value-blacklist */
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 5px fade(#000, 8%);
  border-radius: 2px;
  color: #222;

  &_pos-share {
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      position: absolute;
      top: -6px;
      left: 50%;
      margin-left: -6px;
      background: #fff;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      content: '';
      width: 10px;
      height: 10px;
      border-left: 1px solid #f2f2f2;
      border-top: 1px solid #f2f2f2;
      transform: rotate(45deg);
    }
  }

  &_pos-size {
    bottom: 40px;
    .left(0);

    &::after {
      position: absolute;
      bottom: -6px;
      .left(20px);
      background: #fff;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      content: '';
      width: 12px;
      height: 12px;
      border-right: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      transform: rotate(45deg);
    }
  }

  &_pos-sizeright {
    bottom: 40px;
    .right(0);

    &::after {
      position: absolute;
      bottom: -6px;
      .right(20px);
      background: #fff;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      content: '';
      width: 12px;
      height: 12px;
      border-right: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      transform: rotate(45deg);
    }
  }

  &__pos-addlike {
    bottom: 65px;
    left: 50%;
    transform: translate(-50%);

    &::after {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -6px;
      background: #fff;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      content: '';
      width: 12px;
      height: 12px;
      border-right: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      transform: rotate(45deg);
    }
  }

  &_pos-reviewspop {
    top: 27px;
    .left(0);
    color: #767676;
    line-height: 16px;
    white-space: nowrap;

    span {
      color: #222;
    }

    &::after {
      position: absolute;
      top: -6px;
      .left(31px);
      background: #fff;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      content: '';
      width: 12px;
      height: 12px;
      border-left: 1px solid #f2f2f2;
      border-top: 1px solid #f2f2f2;
      transform: rotate(45deg);
    }
  }
}

.goods-detailv2__promotion {
  .c-modal.modal-content {
    padding: 40px 40px 16px 40px;
  }

  button {
    display: block;
    width: 269px;
    margin: 16px auto;
    height: 44px;
  }

  p {
    font-size: 18px;
  }
}

.goods-detailv2__media {
  width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}
.goods-detailv2__media-inner {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1440px) {
  .goods-detailv2__media {
    width: 1093px + 80px;

    & when(@IS_RW) {
      width: 1280px;
    }
  }
}

@media (max-width: 1366px) {
  .goods-detailv2__media {
    width: 994px + 80px;

    & when(@IS_RW) {
      width: 1180px;
    }
  }
}

@media (max-width: 1150px) {
  .goods-detailv2__media {
    & when(@IS_RW) {
      width: 994px + 80px;
    }
  }
}

@media (max-width: 1074px) {
  .goods-detailv2__media {
    width: 100%;
  }

  .goods-detailv2__media-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .goods-detailv2__media-small {
    width: 1074px;
    .product-intro__main {
      width: 354px;
    }
    .product-intro__gallery {
      width: 414px;
    }
    .product-intro__info {
      width: 530px;
    }
    .outfit-recommend__btn {
      padding: 8px 14px;
      font-size: 13px;
    }
  }
}

@media (min-width: 1440px) and (max-width: 1535px) {
  .goods-detailv2__media-small {
    .product-intro__main {
      width: 453px;
    }
    .product-intro__gallery {
      width: 513px;
    }
    .product-intro__info {
      width: 431px;
    }
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .goods-detailv2__media-small {
    .product-intro__main {
      width: 426px;
    }
    .product-intro__gallery {
      width: 486px;
    }
    .product-intro__info {
      width: 458px;
    }
  }
}

@media (min-width: 1920px) {
  .goods-detailv2__media-small {
    width: 1280px;
    .product-intro__main {
      width: 589px;
    }
    .product-intro__gallery {
      width: 649px;
    }
    .product-intro__info {
      width: 501px;
    }
    .outfit-recommend__btn {
      padding: 10px 16px;
      font-size: 12px;
    }
  }
}

@media (max-width: 1440px) {
  .goods-detailv2__media-expand {
    width: 1280px;
  }
}

@media (max-width: 1366px) {
  .goods-detailv2__media-expand {
    width: 1180px;
  }
}

@media (max-width: 1150px) {
  .goods-detailv2__media-expand {
    width: 1074px;
  }
}

@media (max-width: 1074px) {
  .goods-detailv2__media-expand {
    width: 100%;
  }
}
</style>
