
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * afterpay vaulting 选中态
 */
export const AFTER_PAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * dlocal-nupay vaulting 选中态
 */
export const SIGNUP_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

// 下单地址检测 /v2/User/Address/checkAddress 错误码
export const ADDRESS_ERROR_CODE = [ 401996, 402003, 402004, 402005 ]
