<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  onlyLabelStyle: {
    type: Boolean,
    default: false
  }
})

const vuexStore = useStore()
const language = computed(() => vuexStore.state.language)
const inFashionStoreImg = computed(() => {
  return vuexStore.getters?.inFashionStoreImg
})
const trendsInfo = computed(() => {
  return vuexStore.getters?.trendsInfo
})
const defaultTrendsImgUrl = computed(() => {
  return `${vuexStore.state.PUBLIC_CDN}/she_dist/images/store/infashion_label/default-c07deb21f5.png`
})
const shouldRenderTrendsTitle = computed(() => {
  return trendsInfo.value?.display_title && !props.onlyLabelStyle
})

</script>

<template>
  <span
    class="gd-trends-label"
    :class="{ 'gd-trends-label__hasTitle': shouldRenderTrendsTitle, 'gd-trends-label__hasImg': inFashionStoreImg }"
  >
    <img
      v-if="inFashionStoreImg"
      :src="inFashionStoreImg"
    />
    <span
      v-else
      class="gd-trends-label__default"
    >
      {{ language.SHEIN_KEY_PC_30229 || 'Trends' }}
      <img
        class="gd-trends-label__default_icon"
        :src="defaultTrendsImgUrl"
      />
    </span>
    <span
      v-if="shouldRenderTrendsTitle"
      class="gd-trends-label__title"
      :title="trendsInfo.display_title"
    >
      {{ trendsInfo.display_title }}
    </span>
  </span>
</template>

<style scoped lang="less">
.gd-trends-label {
  img {
    width: 100%;
    height: 100%;
  }

  &__hasTitle {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    &.gd-trends-label__hasImg {
      img {
        width: unset;
        position: relative;
        z-index: 1;
      }
    }
  }
  
  &__default {
    display: flex;
    height: 20px;
    line-height: 20px;
    position: relative;
    padding: 0 7px 0 3px;
    font-size: 16px;
    font-style: italic;
    font-weight: 858;
    color: #fff;
    z-index: 1;
    &_icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  &__title {
    font-weight: normal;
    height: 100%;
    color: #9462FF;
    font-size: 12px;
    background-color: #f4efff;
    padding: 0 7px 0 14px;
    margin-left: -9px;
    border-radius: 0 3px 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
