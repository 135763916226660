<template>
  <label class="selling-point-label__ctn">
    <template v-if="!showDesc">
      <s-label
        v-for="(item, index) in finalShowTag"
        :key="`${item.tag_val_id}_${index}`"
        :ref="`item${index}`"
        type="sellpoint"
      >
        {{ item.tag_val_name_lang }}
      </s-label>
    </template>
    <template v-else>
      <LazyHydrationWrapper :when-triggered="ready">
        <s-popover
          v-for="(item, index) in showTag"
          :key="item.tag_val_id"
          trigger="hover"
          @input="(isShow) => hoverShow(isShow, item)"
        >
          <div class="selling-point-label__title">
            {{ item.tag_val_name_lang }}
          </div>
          <div class="selling-point-label__desc">
            {{ item.tag_val_desc }}
          </div>
          <div
            v-if="item.change === '1'"
            class="selling-point-label__viewMore"
            @click="openSelectProductList(item)"
          >
            {{ language.SHEIN_KEY_PC_17600 }} &gt;
          </div>
          <template #reference>
            <s-label
              :ref="'tag' + index"
              type="sellpoint"
              :style="sellingPointStyle[index]"
            >
              {{ item.tag_val_name_lang }}
            </s-label>
          </template>
        </s-popover>
      </LazyHydrationWrapper>
    </template>
  </label>
</template>

<script>
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import { SPopover } from '@shein-aidc/sui-popover'
import { SLabel } from '@shein-aidc/sui-label'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { replaceHrefSpeCha } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'SellingPoint',
  components: {
    SPopover,
    SLabel,
    LazyHydrationWrapper
  },
  props: {
    tag: {
      type: Array,
      default: () => [],
    },
    showNum: {
      type: Number,
      default: 1,
    },
    showEvolushein: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    ready: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sellingPointStyle: [{}, { marginRight: 0 }],
      showOneOnly: false,
      lazyShow: false
    }
  },
  computed: {
    // 商详使用，经过计算看是否只展示1个
    showTag() {
      return this.showOneOnly
        ? this.finalShowTag.slice(0, 1)
        : this.finalShowTag
    },
    // 列表使用
    finalShowTag() {
      const needShowTag = this.tag.slice(0, this.showNum)
      this.$emit('showSellingPoint', needShowTag || [])
      return needShowTag
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.dealStyle()
    })
  },
  methods: {
    dealStyle() {
      if (!(this.$refs.tag0 || this.$refs.tag1)) return

      let needMinusW = 0
      const marginR = 8 // margin-right
      const rightGap = 75 // 右侧剩余宽度

      // 新品角标
      const newLabelW = document
        .querySelector('.new-label')
        ?.getBoundingClientRect()?.width
      if (newLabelW) {
        needMinusW = newLabelW + marginR
      }

      const maxSellingPointsW =
        document.querySelector('.product-intro__info')?.getBoundingClientRect()
          ?.width - needMinusW
      const sellingPointsOneW =
        this.$refs?.tag0?.[0]?.getBoundingClientRect?.()?.width || 0 + marginR
      const sellingPointsTwoW =
        this.$refs?.tag1?.[0]?.getBoundingClientRect?.()?.width || 0

      // 第一个卖点角标的宽度+右侧间距75px，大于等于最大容纳宽度时
      if (sellingPointsOneW + rightGap >= maxSellingPointsW) {
        this.sellingPointStyle.splice(0, 1, { width: `${maxSellingPointsW}px` })
        this.showOneOnly = true
      }

      // 第一个卖点角标的宽度+右侧间距75px，小于最大容纳宽度时
      if (sellingPointsOneW + rightGap < maxSellingPointsW) {
        if (sellingPointsOneW + sellingPointsTwoW > maxSellingPointsW) {
          this.sellingPointStyle.splice(1, 1, {
            width: `${maxSellingPointsW - sellingPointsOneW}px`,
            marginRight: 0,
          })
        }
        this.showOneOnly = false
      }

      this.$emit(
        'detailShowSellingPoint',
        this.finalShowTag.slice(0, this.showOneOnly ? 1 : 2)
      )
    },
    openSelectProductList(curSellingPoint) {
      const { tag_id, tag_val_id, tag_val_name_en } = curSellingPoint
      // 埋点，点击卖点描述view product
      this.doAnalysis(curSellingPoint, '1-8-6-72')
      const tagValNameEn = tag_val_name_en.split(' ').join('_')
      location.href = replaceHrefSpeCha(
        `/sellingpoint-${tag_id}-${tag_val_id}-${tagValNameEn}.html`
      )
    },
    hoverShow(isShow, curSellingPoint) {
      this.lazyShow = true
      if (isShow) {
        // 埋点，点击卖点
        this.doAnalysis(curSellingPoint, '1-8-6-71')
      }
    },
    doAnalysis({ tag_id, tag_val_id, tag_val_name_en }, daId) {
      daEventCenter.triggerNotice({
        daId,
        extraData: {
          tag_id,
          tag_val_id,
          tag_val_name_en,
        },
      })
    },
  },
  emits: ['showSellingPoint', 'detailShowSellingPoint'],
}
</script>

<style lang="less">
.selling-point-label {
  &__ctn {
    .flexbox();
  }
}
</style>
