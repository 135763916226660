
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const formatTime = (item = {}, lang) => {
  const originTime = item.expect_time 
  if (!originTime) { // 获取qs时效转换用到
    return ''
  }
  let ssrLang = lang || ''
  let resultTime = ''
  let timeFormat = ''
  if (['en', 'il', 'us', 'asia'].includes(ssrLang)) {
    timeFormat = 'MM/DD/YYYY'
  } else if (['au', 'mx', 'hk', 'br', 'ca', 'eur', 'cl', 'ma', 'sg', 'za', 'nz', 'pl', 'ph', 'my', 'ch'].includes(ssrLang)) {
    timeFormat = 'DD/MM/YYYY'
  } else if (['jp', 'ar', 'tw', 'maar'].includes(ssrLang)){
    timeFormat = 'YYYY/MM/DD'
  } else {
    timeFormat = 'DD/MM/YYYY'
  }
  if (originTime.includes('-')) {
    const [start, end] = originTime.split('-')
    const startDate = dayjs(item.current_time)
      .add(8, 'hour')
      .add(start, 'day')
    const endDate = dayjs(item.current_time)
      .add(8, 'hour')
      .add(end, 'day')
      
    resultTime = `${startDate
      .utc()
      .format(timeFormat)} - ${endDate.utc().format(timeFormat)}`
  } else {
    resultTime = dayjs(item.current_time)
      .add(8, 'hour')
      .add(originTime, 'day')
      .utc()
      .format(timeFormat)
  }
  return resultTime
}
