<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="showRetailPricePopover"

      v-model="popoverActive"
      :content="pricePopoverLang"
      placemen="bottom"
      trigger-type="unset"
      show-close-icon
      fix-disappear-position
      :prop-style="popoverStyle"
      class="popover-container"
      @click.stop="popoverClick"
    >
      <template #reference>
        <div 
          class="suggested-popover" 
        >
          <SuggestedSalePrice
            :value="priceValue"
          />
          <i 
            ref="iconCaution"
            :data-id="id"
            class="icon-suggested-caution sh_pc_sui_icon_caution_14px_1"
            :class="{
              'display-none': showIconSuggested === -1,
              'show': showIconSuggested === 1
            }"
          ></i>
        </div>
      </template>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { SPopover } from '@shein-aidc/sui-popover'

import { toRefs } from 'vue'
import { isFrPlSuggestedSalePrice, isDeSuggestedSalePrice } from '../../utils/priceDisplayTheResults'

import { useCardPopover } from '../../hooks/useCardPopover.js'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  specialData: {
    type: Object,
    default: () => {},
  },
  supportVueDebug: {
    type: Boolean,
    default: false,
  }
})

const { goodsInfo, specialData, } = toRefs(props)
const { retailPrice } = goodsInfo.value

const suggestedSalePriceConfigInfo = goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo
const suggestedSalePriceType = suggestedSalePriceConfigInfo?.type

const isFrPl = isFrPlSuggestedSalePrice(suggestedSalePriceType)
const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

const suggestedSalePriceInfo = goodsInfo.value?.suggestedSalePrice

const pricePopoverLang = isDe ? suggestedSalePriceConfigInfo?.pricePopoverLang : ''

// 德国展示原价  法国挪威展示合规价
const priceValue = isFrPl ? suggestedSalePriceInfo : retailPrice

const showRetailPricePopover = specialData.value?.showRetailPricePopover

const {
  id,
  popoverActive,
  iconCaution,
  showIconSuggested,
  popoverStyle,
  popoverClick
} = showRetailPricePopover ? useCardPopover({
  goodsInfo: goodsInfo.value,
}) : {}

</script>

<style lang="less" scoped>
:deep(.popover-container.sui-popover__trigger) {
  display: inline;
}

.prices-info__suggested-sale-price {
  flex: 1;
  min-width: 0;
}
.suggested-popover {
  cursor: pointer;

  display: flex;
  align-items: center;
  line-height: 14px;
}
.icon-suggested-caution {
  margin-left: 2px;
  width: 14px;
  height: 14px;

  opacity: 0;

  &.display-none {
    display: none;
  }
  &.show {
    opacity: 1;
  }
}
</style>
